@charset "utf-8";

.iconBtn {
    width: calc(100% - 10px);
    height: 100%;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    &:before {
        position: absolute;
        right: -10px;
        top: 10px;
        width: 100%;
        height: 100%;
        background-color: $keylb;
        display: inline-block;
        content: ""; }
    >a {
        position: relative;
        border: 5px solid $keylb;
        padding: 20px 20px 10px;
        display: inline-block;
        width: 100%;
        height: 100%;
        transition: 0.5s;
        background-color: #fff;
        text-decoration: none;
        box-sizing: border-box;
        @include mq(maxTb) {
            padding: 15px; }
        >span {
            @include f-size(22);
            color: $keyb;
            position: relative;
            padding-left: 1rem;
            font-weight: bold;
            @include mq(maxTb) {
                @include f-size(20); }
            &:before {
                content: "\f284";
                font-family: ionicons;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%); } }
        >svg {
            margin-top: 20px;
            fill: $keylb;
            @include mq(maxTb) {
                position: absolute;
                margin-top: 0;
                top: 50%;
                left: 10px;
                transform: translateY(-50%); } }
        &:hover, &:focus {
            background-color: $keylb;
            >span {
                color: #fff; }
            >svg {
                fill: #fff; } } }

    &.-search {
        a {
            >svg {
                max-width: 75px;
                max-height: 75px;
                @include mq(maxTb) {
                    max-width: 37px;
                    max-height: 37px; } } } }

    &.-map {
        a {
            >svg {
                max-width: 70px;
                max-height: 70px;
                @include mq(maxTb) {
                    max-width: 35px;
                    max-height: 35px; } } } }

    &.-business {
        a {
            >svg {
                max-width: 74px;
                max-height: 74px;
                @include mq(maxTb) {
                    max-width: 37px;
                    max-height: 37px; } } } } }


.lineBtn {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px 20px;
    box-sizing: border-box;
    border: 3px solid $keylb;
    text-decoration: none;
    color: $keyb;
    transition: 0.5s;
    @include f-size(20);
    font-weight: bold;
    background-color: #fff;
    @include mq(maxTb) {
        @include f-size(16);
        padding: 10px 20px; }
    &:hover, &:focus {
        background-color: $keylb;
        color: #fff; }
    &.-short {
        @include f-size(16);
        padding: 5px 10px; }
    &.-company {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            text-align: left; } }
    &.-youtube {
        >span {
            padding-left: 4rem;
            @include mq(maxTb) {
                padding-left: 3.5rem; }
            &:before {
                transition: 0.5s;
                content: "\f34f";
                color: #ff0000;
                line-height: 1;
                margin-top: 1px;
                @include f-size(30);
                @include mq(maxTb) {
                    @include f-size(25);
                    margin-top: 0; } } }
        &:hover, &:focus {
            >span {
                &:before {
                    color: #fff; } } } }

    >span {
        position: relative;
        padding-left: 1.5rem;
        display: inline-block;
        &:before {
            content: "\f284";
            font-family: "ionicons";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%); } } }

a.lineBtn[target="_blank"] {
    >span {
        padding-right: 2rem;
        &:after {
            content: "\f33b";
            font-family: "ionicons";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%); } } }
