@charset "utf-8";

.wysiwyg {
    p {
        min-height: 1em;
        margin: 0; }
    h2 {
        background-image: repeating-linear-gradient(-45deg, #d0e9f5, #d0e9f5 6px, #fff 0, #fff 8px);
        color: $keybb;
        @include f-size(24);
        padding: 20px;
        margin: 0;
        @include mq(maxTb) {
            @include f-size(17);
            padding: 10px 15px; } }

    h2 + * {
        margin-top: 30px;
        @include mq(maxTb) {
            margin-top: 20px; } }

    h3 {
        @include f-size(20);
        padding-bottom: 10px;
        border-bottom: 4px solid #1ebee3;
        @include mq(maxTb) {
            @include f-size(16); } }

    h3 + * {
        margin-top: 30px;
        @include mq(maxTb) {
            margin-top: 20px; } }

    h4 {
        @include f-size(18);
        padding-bottom: 10px;
        border-bottom: 2px solid #999;
        @include mq(maxTb) {
            @include f-size(15); } }

    h4 + * {
        margin-top: 30px;
        @include mq(maxTb) {
            margin-top: 20px; } }

    a[target ='_blank'] {
        position: relative;
        padding-right: 1em;
        text-decoration: none;
        &:hover,&:focus {
            span {
                text-decoration: none; } }
        &:after {
            position: absolute;
            content: "\f33b";
            font-family: "ionicons";
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
        span {
            text-decoration: underline; } }
    a[href $='.pdf'] {
        position: relative;
        padding-right: 1em;
        text-decoration: none;
        &:hover,&:focus {
            span {
                text-decoration: none; } }
        &:after {
            position: absolute;
            content: "\f1c1";
            font-family: "Font Awesome 5 Free";
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
        span {
            text-decoration: underline; } }
    a[href $='.doc'] {
        position: relative;
        padding-right: 1em;
        text-decoration: none;
        &:hover,&:focus {
            span {
                text-decoration: none; } }
        &:after {
            position: absolute;
            content: "\f1c2";
            font-family: "Font Awesome 5 Free";
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
        span {
            text-decoration: underline; } }
    a[href $='.xls'] {
        position: relative;
        padding-right: 1em;
        text-decoration: none;
        &:after {
            position: absolute;
            content: "\f1c3";
            font-family: "Font Awesome 5 Free";
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
        span {
            text-decoration: underline; } }
    a[href $='.zip'] {
        position: relative;
        padding-right: 1em;
        text-decoration: none;
        &:hover,&:focus {
            span {
                text-decoration: none; } }
        &:after {
            position: absolute;
            content: "\f1c6";
            font-family: "Font Awesome 5 Free";
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
        span {
            text-decoration: underline; } }
    ul {
        list-style-type: none;
        >li {
            position: relative;
            padding-left: 1.5em;
            text-decoration: none;
            &:after {
                position: absolute;
                content: "\f381";
                font-family: "ionicons";
                left: 0.5em;
                top: 0;
                color: $keybb; } } }

    ol {
        margin: 0;
        padding-left: 1.5em; }

    table {
        border-collapse: collapse;
        border: 1px solid #999;
        width: 100%;
        tr {
            @include mq(maxTb) {
                width: 100%;
                display: block;
                float: left; }
            th {
                background-color: #f2f2f2;
                border: 1px solid #999;
                padding: 20px;
                vertical-align: top;
                text-align: left;
                white-space: nowrap;
                @include mq(maxTb) {
                    width: 100%;
                    display: block;
                    float: left;
                    white-space: inherit;
                    padding: 10px;
                    border: 0px solid #999;
                    border-bottom: 1px solid #999; } }
            td {
                border: 1px solid #999;
                padding: 20px;
                @include mq(maxTb) {
                    width: 100%;
                    display: block;
                    float: left;
                    padding: 10px;
                    border: 0px solid #999;
                    border-bottom: 1px solid #999; } }
            &:last-of-type {
                td {
                    @include mq(maxTb) {
                        border-bottom: 0px solid #999; } } } } } }


