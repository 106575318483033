@charset "utf-8";

header {
    width: 100%;
    border-top: 8px solid $keylb;
    padding: 30px 0 0;
    box-sizing: border-box;
    @include mq(maxTb) {
        padding: 0;
        border-top: 0 solid $keylb; }
    >.inner {
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: content-box;
        padding: 0 30px;
        @include mq(pcWide) {
            position: relative;
            padding: 0 20px; }
        @include mq(maxTb) {
            padding: 0; }
        >.left {
            display: flex;
            align-items: center;
            width: 612px;
            @include mq(pcWide) {
                max-width: 243px;
                width: 30%; }
            @include mq(maxTb) {
                width: 100%;
                display: block; }
            >.ttl {
                @include mq(maxTb) {
                    border-top: 4px solid $keylb;
                    padding: 20px;
                    width: 100%;
                    display: block;
                    position: fixed;
                    left: 0;
                    top: 0;
                    z-index: 1000;
                    background-color: #fff;
                    min-height: 80px;
                    border-bottom: 1px solid #e6e6e6;
                    .active & {
                        background-color: #2c2d2f;
                        border-bottom: 1px solid #2c2d2f; } }
                h1 {
                    margin: 0;
                    a {
                        transition: 0.5s;
                        @include mq(maxTb) {
                            .active & {
                                opacity: 0;
                                pointer-events: none;
                                &:hover,&:focus {
                                    opacity: 0; } } }

                        &:hover,&:focus {
                            opacity: 0.5; }
                        >img {
                            width: 243px;
                            vertical-align: bottom;
                            @include mq(maxTb) {
                                width: 40%;
                                max-width: 138px;
                                min-width: 138px; } } } }
                >.inner {
                    @include mq(maxTb) {
                        position: relative; }
                    .vue-ham {
                        display: none;
                        @include mq(maxTb) {
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 1000; } } } }

            >.txt {
                margin-left: 60px;
                @include mq(pcWide) {
                    margin-left: 0;
                    position: absolute;
                    top: 0;
                    left: 20px;
                    transform: translateY(-90%); }
                @include mq(maxTb) {
                    display: none;
                    background-color: $keybb;
                    margin-left: 0;
                    padding: 10px;
                    .theme-top & {
                        display: block; } }
                p {
                    color: $keybb;
                    font-weight: bold;
                    @include f-size(18);
                    margin: 0;
                    @include mq(pcWide) {
                        @include f-size(16); }
                    @include mq(maxTb) {
                        color: #fff;
                        @include f-size(19);
                        text-align: center; }
                    >span {
                        &.line {
                            @include mq(wide) {
                                display: block; }
                            @include mq(maxTb) {
                                display: block;
                                font-weight: normal; } }
                        &.blue {
                            color: #fff;
                            background-color: $keybb;
                            padding: 5px 5px;
                            margin-left: 0.5rem;
                            font-size: 0.8em;
                            @include mq(maxTb) {
                                color: $keybb;
                                background-color: #fff; } } } } } }
        >.right {
            @include mq(maxTb) {
                display: none; }
            >.btn {
                margin: 10px 0;
                >ul {
                    display: flex;
                    margin: 0 0 0 138px;
                    width: calc(100% - 138px);
                    @include mq(pcWide) {
                        margin: 0;
                        width: 100%; }
                    >li {
                        position: relative;
                        width: calc((100% - 10px) / 2);
                        &:nth-of-type(2) {
                            margin-left: 10px; }
                        >.caption {
                            position: absolute;
                            left: 50%;
                            text-outline: 0;
                            transform: translate(-50%, -100%);
                            color: $keybb;
                            display: inline-block;
                            margin: 0;
                            white-space: nowrap;
                            font-weight: bold;
                            &:before {
                                content: "";
                                width: 1px;
                                height: 1em;
                                display: inline-block;
                                background-color: $keybb;
                                transform: rotate(-30deg) translateY(2px);
                                margin-right: 10px; }
                            &:after {
                                content: "";
                                width: 1px;
                                height: 1em;
                                display: inline-block;
                                background-color: $keybb;
                                transform: rotate(30deg) translateY(2px);
                                margin-left: 10px; } }
                        .lineBtn {
                            @include f-size(16);
                            padding: 5px 10px; } } } }

            >.search {
                >form {
                    >fieldset {
                        >.inner {
                            display: flex;
                            align-items: center;
                            label {
                                margin-right: 10px; }
                            .box {
                                display: flex;
                                input[type="text"] {
                                    border: 1px solid #000;
                                    padding: 10px;
                                    width: 320px;
                                    box-sizing: border-box;
                                    @include mq(pcWide) {
                                        width: 246px; } }
                                .submit {
                                    >.btn {
                                        position: relative;
                                        height: 100%;
                                        background-color: #000;
                                        color: #fff;
                                        padding: 5px 20px 5px 30px;
                                        height: 100%;
                                        border: 1px solid #000;
                                        border-left: 0 solid #000;
                                        transition: 0.5s;
                                        &:before {
                                            content: "\f284";
                                            font-family: "ionicons";
                                            position: absolute;
                                            top: 50%;
                                            left: 15px;
                                            transform: translateY(-50%);
                                            color: #fff;
                                            transition: 0.5s; }
                                        &:hover, &:focus {
                                            color: #000;
                                            cursor: pointer;
                                            background-color: #fff;
                                            &:before {
                                                color: #000; } } } } } } } } } } }

    .spNavOuter {
        @include mq(maxTb) {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            transform: translate(100%, 0);
            z-index: 999;
            opacity: 0;
            background-color: #2c2d2f;
            color: #fff;
            box-sizing: border-box;
            &:before {
                content: ""; }
            .active & {
                display: inline-block;
                transform: translate(0, 0);
                opacity: 1;
                transition: 0.5s; } }
        >.inner {
            @include mq(maxTb) {
                padding: 20px;
                height: 100%;
                box-sizing: border-box; }
            >.scroll {
                @include mq(maxTb) {
                    overflow-y: auto;
                    height: 100%;
                    padding-right: 5px;
                    &::-webkit-scrollbar {
                        width: 5px;
                        background: #ccc; }
                    &::-webkit-scrollbar-thumb {
                        background: #888;
                        border-radius: 2px; } }

                >.search {
                    @include mq(minTb) {
                        display: none; }
                    >form {
                        >fieldset {
                            >.searchInner {
                                display: flex;
                                align-items: center;
                                label {
                                    margin-right: 10px; }
                                .box {
                                    display: flex;
                                    width: 100%;
                                    input[type="text"] {
                                        border: 1px solid #000;
                                        padding: 10px;
                                        width: calc(100% - 79px);
                                        box-sizing: border-box;
                                        border-radius: 0;
                                        -webkit-appearance: none;
                                        @include f-size(16);
                                        height: 40px; }
                                    .submit {
                                        >.btn {
                                            position: relative;
                                            height: 100%;
                                            background-color: #000;
                                            color: #fff;
                                            padding: 5px 20px 5px 30px;
                                            height: 40px;
                                            border: 1px solid #000;
                                            border-left: 0 solid #000;
                                            transition: 0.5s;
                                            &:before {
                                                content: "\f284";
                                                font-family: "ionicons";
                                                position: absolute;
                                                top: 50%;
                                                left: 15px;
                                                transform: translateY(-50%);
                                                color: #fff;
                                                transition: 0.5s; }
                                            &:hover, &:focus {
                                                color: #000;
                                                cursor: pointer;
                                                background-color: #fff;
                                                &:before {
                                                    color: #000; } } } } } } } } }
                >nav {
                    @include mq(maxTb) {
                        >ul {
                            >li {
                                display: block;
                                border-top: 1px solid #fff;
                                &:last-of-type {
                                    border-bottom: 1px solid #fff; }
                                >a {
                                    color: #fff;
                                    text-decoration: none;
                                    position: relative;
                                    padding: 10px 10px 10px 25px;
                                    display: inline-block;
                                    width: 100%;
                                    box-sizing: border-box;
                                    transition: 0.5s;
                                    &:hover, &:focus {
                                        opacity: 0.5; }
                                    &:before {
                                        content: "\f284";
                                        font-family: ionicons;
                                        position: absolute;
                                        top: 50%;
                                        left: 10px;
                                        transform: translateY(-50%); } } } } }


                    @include mq(minTb) {
                        border: 1px solid #e6e6e6;
                        border-bottom: 6px solid #e6e6e6;
                        margin-top: 30px;
                        @include mq(pcWide) {
                            margin-top: calc( 4rem + 15px );
                            margin-top: 20px; }
                        >ul {
                            display: flex;
                            max-width: $maxWidth;
                            margin: 0 auto;
                            >li {
                                width: calc(100% / 5);
                                text-align: center;
                                margin-bottom: -6px;
                                &:nth-of-type(1) {
                                    >a {
                                        border-left: 1px solid #e6e6e6;

                                        .theme-top & {
                                            border-bottom: 6px solid $keyly; } } }
                                &:nth-of-type(2) {
                                    >a {
                                        .theme-conditions & {
                                            border-bottom: 6px solid $keyly; } } }

                                &:nth-of-type(3) {
                                    >a {
                                        .theme-map & {
                                            border-bottom: 6px solid $keyly; } } }

                                &:nth-of-type(4) {
                                    >a {
                                        .theme-companies & {
                                            border-bottom: 6px solid $keyly; }
                                        .theme-campany & {
                                            border-bottom: 6px solid $keyly; } } }

                                &:nth-of-type(5) {
                                    >a {
                                        .theme-business & {
                                            border-bottom: 6px solid $keyly; } } }

                                &:nth-of-type(6) {
                                    >a {
                                        .theme-event & {
                                            border-bottom: 6px solid $keyly; } } }
                                &.sp {
                                    display: none; }
                                >a {
                                    padding: 25px 10px;
                                    display: inline-block;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    border: 1px solid #e6e6e6;
                                    border-top: 0 solid #e6e6e6;
                                    border-left: 0 solid #e6e6e6;
                                    border-bottom: 6px solid #e6e6e6;
                                    text-decoration: none;
                                    color: $keyb;
                                    font-weight: bold;
                                    @include f-size(18);
                                    @include mq(pcWide) {
                                        @include f-size(16); }
                                    @include mq(minPcLimit) {
                                        padding: 25px 5px; }
                                    @include mq(pc) {
                                        padding: 10px 5px; }
                                    &:hover, &:focus {
                                        border-bottom: 6px solid $keyly; }
                                    span {
                                        &.middle {
                                            @include mq(pc) {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                height: 100%;
                                                width: 100%; } }
                                        &.line {
                                            @include mq(pc) {
                                                display: block; } } } } } } } } } } } }

