@charset "utf-8";

.topIconList {
    display: flex;
    max-width: 908px;
    margin: 0 auto;
    @include mq(maxTb) {
        display: block; }
    >li {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        @include mq(maxTb) {
            width: 100%;
            margin-right: 0; }
        &:nth-of-type(3n) {
            margin-right: 0; } }
    li + li {
        @include mq(maxTb) {
            margin-top: 20px; } } }

.waveTtl + .topIconList {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 40px; } }


.topBtnList {
    display: flex;
    max-width: 740px;
    margin: 0 auto;
    @include mq(maxTb) {
        display: block;
        max-width: 335px;
        width: 100%; }
    >li {
        width: calc((100% - 40px) / 2);
        margin-right: 40px;
        @include mq(maxTb) {
            width: 100%;
            margin-right: 0; }
        &:nth-of-type(2n) {
            margin-right: 0;
            @include mq(maxTb) {
                margin-top: 20px; } } } }

* + .topBtnList {
    margin-top: 70px;
    @include mq(maxTb) {
        margin-top: 40px; } }

.companyList {
    display: flex;
    flex-wrap: wrap;
    &.-search {
        >li {
            width: calc((100% - 90px) / 4);
            margin-right: 30px;
            @include mq(pc) {
                width: calc((100% - 60px) / 3);
                .ie & {
                    width: calc((100% - 61px) / 3); } }
            @include mq(tb) {
                width: calc((100% - 15px) / 2);
                margin-right: 15px; }
            @include mq(sp) {
                width: 100%;
                margin-right: 0; }
            &:nth-of-type(5n) {
                @include mq(wide) {
                    margin-right: 30px; } }
            &:nth-of-type(4n) {
                @include mq(minPc) {
                    margin-right: 0; } }
            &:nth-of-type(n + 5) {
                @include mq(minPc) {
                    margin-top: 30px; } }
            &:nth-of-type(3n) {
                @include mq(pc) {
                    margin-right: 0; } }
            &:nth-of-type(n + 4) {
                @include mq(pc) {
                    margin-top: 30px; } }
            &:nth-of-type(2n) {
                @include mq(tb) {
                    margin-right: 0; } }
            &:nth-of-type(n + 3) {
                @include mq(tb) {
                    margin-top: 20px; } }
            &:nth-of-type(n + 2) {
                @include mq(sp) {
                    margin-top: 20px; } }
            .imgOuter {
                .img {
                    &:before {
                        padding-top: 72%; } } } } }

    >li {
        width: calc((100% - 120px) / 5);
        margin-right: 30px;
        @include mq(minPcLimit) {
            width: calc((100% - 90px) / 4); }
        @include mq(pc) {
            width: calc((100% - 60px) / 3);
            .ie & {
                width: calc((100% - 61px) / 3); } }
        @include mq(tb) {
            width: calc((100% - 15px) / 2);
            margin-right: 15px; }
        @include mq(sp) {
            width: 100%;
            margin-right: 0; }

        &:nth-of-type(5n) {
            @include mq(wide) {
                margin-right: 0; } }
        &:nth-of-type(n + 6) {
            @include mq(wide) {
                margin-top: 30px; } }
        &:nth-of-type(4n) {
            @include mq(minPcLimit) {
                margin-right: 0; } }
        &:nth-of-type(n + 5) {
            @include mq(minPcLimit) {
                margin-top: 30px; } }
        &:nth-of-type(3n) {
            @include mq(pc) {
                margin-right: 0; } }
        &:nth-of-type(n + 4) {
            @include mq(pc) {
                margin-top: 30px; } }
        &:nth-of-type(2n) {
            @include mq(tb) {
                margin-right: 0; } }
        &:nth-of-type(n + 3) {
            @include mq(tb) {
                margin-top: 20px; } }
        &:nth-of-type(n + 2) {
            @include mq(sp) {
                margin-top: 20px; } }

        a {
            transition: 0.5s;
            text-decoration: none;
            display: inline-block;
            width: 100%;
            &:hover, &:focus {
                opacity: 0.5;
                .imgOuter {
                    .img {
                        >.imgBg {
                            width: 105%;
                            height: 105%; } } }
                .txt {
                    .name {
                        text-decoration: none; } } } }


        .imgOuter {
            background-image: repeating-linear-gradient(-45deg, #d0e9f5, #d0e9f5 6px, #fff 0, #fff 8px);
            padding: 10px;
            .img {
                width: 100%;
                overflow: hidden;
                position: relative;
                &:before {
                    content: "";
                    padding-top: 72%;
                    display: block; }
                >.imgBg {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    position: absolute;
                    background-position: center center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: 0.5s; } } }
        .txt {
            @include mq(maxTb) {
                @include f-size(14); }
            .name {
                margin: 10px 0;
                text-decoration: underline; }
            .caption {
                margin: 10px 0;
                color: $keyb; }
            >table {
                color: $keyb;
                tr {
                    th {
                        font-weight: normal;
                        width: 7rem;
                        vertical-align: top;
                        @include mq(maxTb) {
                            width: 4em; }
                        span {
                            background-color: $keybb;
                            color: #fff;
                            padding: 5px;
                            display: inline-block;
                            width: 100%;
                            text-align: center; } }
                    td {
                        padding: 5px 0 0 5px; } }

                tr + tr {
                    th {
                        padding-top: 10px; }
                    td {
                        padding-top: 15px; } } } } } }

.centerTxt + .companyList {
    margin-top: 40px; }

.displayTxt + .companyList {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 30px; } }

.newsList {
    display: flex;
    flex-wrap: wrap;
    >li {
        width: calc((100% - 90px) / 4);
        margin-right: 30px;
        color: #fff;
        @include mq(pc) {
            width: calc((100% - 60px) / 3); }
        @include mq(tb) {
            width: calc((100% - 15px) / 2);
            margin-right: 15px; }
        @include mq(sp) {
            width: 100%;
            margin-right: 0; }
        &:nth-of-type(4n) {
            @include mq(minPc) {
                margin-right: 0; } }
        &:nth-of-type(3n) {
            @include mq(pc) {
                margin-right: 0; } }
        &:nth-of-type(n + 4) {
            @include mq(pc) {
                margin-top: 30px; } }
        &:nth-of-type(2n) {
            @include mq(tb) {
                margin-right: 0; } }
        &:nth-of-type(n + 3) {
            @include mq(tb) {
                margin-top: 20px; } }
        &:nth-of-type(n + 2) {
            @include mq(sp) {
                margin-top: 20px; } }
        a {
            color: #fff;
            text-decoration: none;
            display: inline-block;
            transition: 0.5s;
            width: 100%;
            &:hover, &:focus {
                opacity: 0.5;
                .imgOuter {
                    .img {
                        >.imgBg {
                            width: 105%;
                            height: 105%; } } }
                .txt {
                    text-decoration: none; } } }
        .imgOuter {
            background-color: #fff;
            padding: 10px;
            .img {
                width: 100%;
                overflow: hidden;
                position: relative;
                &:before {
                    content: "";
                    padding-top: 74%;
                    display: block; }
                >.imgBg {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    position: absolute;
                    background-position: center center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: 0.5s; } } }
        .registration {
            margin: 0 0 20px 0;
            @include mq(maxTb) {
                margin: 0 0 10px 0; } }
        .held {
            margin: 20px 0 0 0;
            @include mq(maxTb) {
                margin: 10px 0 0 0; } }
        .txt {
            margin: 10px 0 0 0;
            text-decoration: underline;
            @include mq(maxTb) {
                margin: 5px 0 0 0; } } } }


.bnrList {
    justify-content: left;
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    @include mq(sp) {
        align-items: center; }
    >li {
        margin: 0 20px;
        @include f-size(0);
        @include mq(minPc) {
            width: calc((100% - 150px) / 4);
            margin: 0 50px 0 0; }
        @include mq(pc) {
            width: calc((100% - 30px) / 4);
            margin: 0 10px 0 0; }
        @include mq(maxTb) {
            width: calc((100% - 15px) / 2);
            margin: 0; }
        @include mq(tb) {
            margin-right: 15px; }
        @include mq(sp) {
            width: 100%;
            text-align: center;
            margin: 0; }

        &:nth-of-type(2n) {
            @include mq(tb) {
                margin-right: 0; } }


        &:nth-of-type(4n) {
            @include mq(minPc) {
                margin: 0 0 0 0; }
            @include mq(pc) {
                margin: 0 0 0 0; } }


        &:nth-of-type(n + 2) {
            @include mq(sp) {
                margin-top: 15px; } }

        &:nth-of-type(n + 3) {
            @include mq(maxTb) {
                margin-top: 15px; } }


        &:nth-of-type(n + 5) {
            @include mq(minPc) {
                margin-top: 20px; }
            @include mq(pc) {
                margin-top: 15px; }
            @include mq(maxTb) {
                margin-top: 15px; }
            @include mq(sp) {
                margin-top: 15px; } }

        img {
            vertical-align: bottom;
            max-width: 100%;
            width: 100%;
            @include mq(maxTb) {
                width: 100%;
                max-width: 320px; } }

        a {
            transition: 0.5s;
            display: inline-block;
            @include f-size(16);
            @include mq(maxTb) {
                width: 100%;
                max-width: 320px; }
            &:hover, &:focus {
                opacity: 0.5; } } } }

ol.pankuzu {
    display: flex;
    list-style-type: none;
    max-width: $maxWidth;
    margin: 20px auto 0;
    box-sizing: content-box;
    padding: 0 20px;
    @include mq(maxTb) {
        display: none; }
    >li {
        &:after {
            content: ">";
            margin: 10px; }
        &:last-of-type {
            &:after {
                display: none; } } } }

.searchNav {
    @include mq(minTb) {
        display: none; }
    display: flex;
    margin: 0;
    &.-bottom {
        >li {
            > a {
                border-top: 6px solid #e6e6e6;
                border-bottom: 0px solid #e6e6e6;
                &:hover, &:focus {
                    border-top: 6px solid $keyly;
                    border-bottom: 0px solid $keyly; } }
            &.-active {
                > a {
                    border-top: 6px solid $keyly;
                    border-bottom: 0px solid $keyly; } } } }
    >li {
        width: calc(100% / 3);
        border-right: 1px solid #e6e6e6;
        text-align: center;
        &:last-of-type {
            border-right: 0 solid #e6e6e6; }
        &.-active {
            >a {
                border-bottom: 6px solid $keyly;
                span {
                    padding-left: 0;
                    &:before {
                        display: none; } } } }
        >a {
            @include f-size(16);
            font-weight: bold;
            color: $keyb;
            text-decoration: none;
            padding: 20px 10px;
            width: 100%;
            height: 100%;
            display: inline-block;
            box-sizing: border-box;
            border-bottom: 6px solid #e6e6e6;
            cursor: pointer;
            >span {
                position: relative;
                padding-left: 1.0em;
                @include mq(sp) {
                    padding-left: 0.5em; }
                &:before {
                    content: "\f284";
                    font-family: ionicons;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%); } }
            &:hover, &:focus {
                border-bottom: 6px solid $keyly; } } } }



ul.nameList {
    margin: 30px 0 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mq(minPcLimit) {
        justify-content: normal;
        flex-wrap: wrap;
        margin: 10px 0 0 -20px;
        width: calc(100% + 20px); }
    @include mq(pc) {
        justify-content: normal;
        flex-wrap: wrap;
        margin: 10px 0 0 -10px;
        width: calc(100% + 10px); }
    @include mq(tb) {
        justify-content: normal;
        flex-wrap: wrap;
        margin: 10px 0 0 -20px;
        width: calc(100% + 20px); }
    @include mq(sp) {
        justify-content: normal;
        flex-wrap: wrap;
        margin: 10px 0 0 -10px;
        width: calc(100% + 10px); }

    >li {
        width: 100px;
        height: 100px;
        display: inline-block;
        &.-active {
            >a {
                background-color: $keybb;
                color: #fff;
                border: 4px solid $keybb;
                .inner {
                    &:before {
                        display: none; } } } }
        @include mq(minPcLimit) {
            margin: 20px 0 0 20px;
            width: calc((100% - 200px) / 10);
            height: auto;
            box-sizing: border-box;
            .ie & {
                width: calc((100% - 201px) / 10); } }
        @include mq(pc) {
            margin: 20px 0 0 10px;
            width: calc((100% - 100px) / 10);
            height: auto;
            box-sizing: border-box;
            .ie & {
                width: calc((100% - 101px) / 10); } }
        @include mq(tb) {
            margin: 20px 0 0 20px;
            width: calc((100% - 100px) / 5);
            height: auto;
            box-sizing: border-box;
            .ie & {
                width: calc((100% - 101px) / 5); } }
        @include mq(sp) {
            margin: 20px 0 0 10px;
            width: calc((100% - 50px) / 5);
            height: auto;
            box-sizing: border-box;
            .ie & {
                width: calc((100% - 51px) / 5); } }
        >a {
            display: flex;
            height: 100%;
            width: 100%;
            text-align: center;
            border: 4px solid $keylb;
            color: $keyb;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            &:hover, &:focus {
                background-color: $keybb;
                color: #fff;
                border: 4px solid $keybb; }
            >.inner {
                position: relative;
                @include f-size(30);
                @include mq(minPcLimit) {
                    font-size: 2.6vw;
                    padding: 10px 0;
                    box-sizing: border-box; }
                @include mq(pc) {
                    font-size: 2.6vw;
                    padding: 15px 0;
                    box-sizing: border-box; }
                @include mq(tb) {
                    font-size: 3.2vw;
                    padding: 15px 0;
                    box-sizing: border-box; }
                @include mq(sp) {
                    font-size: 3.8vw;
                    padding: 15px 0;
                    box-sizing: border-box; }

                >span {
                    @include f-size(18);
                    @include mq(minPcLimit) {
                        font-size: 1.4vw; }
                    @include mq(pc) {
                        font-size: 1.4vw; }
                    @include mq(tb) {
                        font-size: 1.8vw; }
                    @include mq(sp) {
                        font-size: 2vw; } }

                &:before {
                    content: "\f284";
                    font-family: ionicons;
                    position: absolute;
                    top: 50%;
                    left: -10px;
                    transform: translateY(-50%);
                    @include f-size(18);
                    @include mq(minPcLimit) {
                        font-size: 1.8vw;
                        left: -6px; }
                    @include mq(pc) {
                        font-size: 1.4vw;
                        left: -4px; }
                    @include mq(tb) {
                        font-size: 1.8vw;
                        left: -6px; }
                    @include mq(sp) {
                        font-size: 2vw;
                        left: -6px; } } } } } }

.tabList {
    background-image: repeating-linear-gradient(-45deg,#31353a,#31353a 6px,#505357 0,#505357 8px);
    display: flex;
    padding-top: 10px;
    @include mq(maxTb) {
        display: none; }
    >li {
        margin: 0 20px;
        width: calc((100% - 120px) / 4);
        position: relative;
        @include mq(minPcLimit) {
            margin: 0 10px;
            width: calc((100% - 60px) / 4); }
        @include mq(pc) {
            margin: 0 10px;
            width: calc((100% - 60px) / 4); }
        &.-active {
            >a {
                background-color: #fff;
                box-sizing: border-box;
                color: $keyb;
                >span {
                    &:before {
                        display: none; } } } }
        >.caption {
            position: absolute;
            left: 50%;
            text-outline: 0;
            transform: translate(-50%, -160%);
            color: $keybb;
            display: inline-block;
            margin: 0;
            white-space: nowrap;
            font-weight: bold;
            @include f-size(18);
            @include mq(minPcLimit) {
                @include f-size(16); }
            @include mq(pc) {
                @include f-size(16); }

            &:before {
                content: "";
                width: 1px;
                height: 1em;
                display: inline-block;
                background-color: $keybb;
                transform: rotate(-30deg) translateY(2px);
                margin-right: 10px; }
            &:after {
                content: "";
                width: 1px;
                height: 1em;
                display: inline-block;
                background-color: $keybb;
                transform: rotate(30deg) translateY(2px);
                margin-left: 10px; } }
        >a {
            display: flex;
            width: 100%;
            height: 100%;
            box-sizing-border-box {}
            padding: 20px;
            color: #fff;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            @include f-size(18);
            font-weight: bold;
            transition: 0.2s;
            @include mq(minPcLimit) {
                @include f-size(16);
                padding: 20px 15px; }
            @include mq(pc) {
                @include f-size(16);
                padding: 20px 20px; }
            &:hover, &:focus {
                background-color: #fff;
                box-sizing: border-box;
                color: $keyb; }
            >.inner {
                display: inline-block;
                position: relative;
                margin-bottom: 10px;
                &:before {
                    content: "\f284";
                    font-family: ionicons;
                    position: absolute;
                    top: 50%;
                    left: -0.8em;
                    transform: translateY(-50%);
                    @include mq(minPcLimit) {
                        left: -0.5em; }
                    @include mq(pc) {
                        left: -0.8em; } }
                span {
                    @include mq(pc) {
                        display: block; } } } } } }


.supportList {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: center;
    @include mq(maxTb) {
        display: block; }
    >li + li {
        @include mq(maxTb) {
            margin-top: 20px; } }

    >li {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        @include mq(pc) {
            margin-right: 15px;
            width: calc((100% - 30px) / 3); }
        @include mq(maxTb) {
            width: 100%;
            margin-right: 0; }
        &:last-of-type {
            margin-right: 0;
            @include mq(pc) {
                margin-right: 0; } }


        >.ttl {
            background-color: $keybb;
            color: #fff;
            padding: 10px 15px;
            @include f-size(18);
            margin: 0;
            @include mq(minPcLimit) {
                @include f-size(16); }
            @include mq(pc) {
                @include f-size(16); } }
        >.txt {
            margin: 20px 0 40px;
            @include mq(maxTb) {
                margin: 20px 0 20px; } }
        .imgOuter {
            background-image: repeating-linear-gradient(-45deg, #e6e6e6, #e6e6e6 6px, #fff 0, #fff 8px);
            padding: 10px;
            @include mq(maxTb) {
                max-width: 320px;
                width: 100%;
                margin: 0 auto; }
            .img {
                width: 100%;
                overflow: hidden;
                position: relative;
                &:before {
                    content: "";
                    padding-top: 70%;
                    display: block; }
                >.imgBg {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    position: absolute;
                    background-position: center center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: 0.5s; } } } } }

.companyBtnList {
    display: flex;
    max-width: 890px;
    margin: 0 auto;
    padding: 80px 0;
    @include mq(pc) {
        display: block; }
    @include mq(maxTb) {
        display: block;
        max-width: 530px;
        padding: 40px 20px;
        border-bottom: 1px solid #999; }
    li {
        width: calc((100% - 60px) / 3);
        margin-right: 30px;
        &:nth-of-type(3n) {
            margin-right: 0; }
        @include mq(pc) {
            width: 100%;
            margin-right: 0; }
        @include mq(maxTb) {
            width: 100%;
            margin-right: 0; } }
    li + li {
        @include mq(pc) {
            margin-top: 20px; }
        @include mq(maxTb) {
            margin-top: 20px; } } }

.processList {
    padding: 0;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    >li {
        list-style-type: none;
        width: calc((100% - 60px) / 3);
        position: relative;
        @include mq(minPc) {
            margin-right: 30px; }
        @include mq(pc) {
            margin-right: 30px; }
        @include mq(maxTb) {
            width: 100%;
            padding: 30px 0;
            border-bottom: 1px dotted #000; }
        &:nth-of-type(3n) {
            @include mq(minPc) {
                margin-right: 0; }
            @include mq(pc) {
                margin-right: 0; }
            .imgOuter {
                &:before {
                    display: none; }
                &:after {
                    display: none; } } }
        &:nth-of-type(n + 4) {
            @include mq(minPc) {
                margin-top: 30px; }
            @include mq(pc) {
                margin-top: 30px; } }
        &:last-of-type {
            &:before {
                display: none; }
            &:after {
                display: none; }
            .imgOuter {
                &:before {
                    display: none; }
                &:after {
                    display: none; } } }
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -15px;
            background-color: $keyly;
            transform: translateX(-50%);
            width: 50px;
            height: 30px;
            display: inline-block;
            @include mq(minTb) {
                display: none; } }

        &:after {
            content: "\f280";
            font-family: ionicons;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            color: $keybb;
            @include f-size(38);
            line-height: 0;
            @include mq(minTb) {
                display: none; } }
        .imgOuter {
            background-image: repeating-linear-gradient(-45deg, #e6e6e6, #e6e6e6 6px, #fff 0, #fff 8px);
            padding: 10px;
            position: relative;
            @include mq(maxTb) {
                max-width: 160px;
                width: 100%;
                margin: 0 auto; }
            &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(100%, -50%);
                background-color: $keyly;
                width: 30px;
                height: 65px;
                display: inline-block;
                @include mq(maxTb) {
                    display: none; } }

            &:after {
                content: "\f284";
                font-family: ionicons;
                position: absolute;
                top: 50%;
                right: -21px;
                transform: translateY(-50%);
                color: $keybb;
                @include f-size(38);
                @include mq(maxTb) {
                    display: none; } }

            .img {
                width: 100%;
                overflow: hidden;
                position: relative;
                &:before {
                    content: "";
                    padding-top: 70%;
                    display: block; }
                >.imgBg {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: 0.5s; } } }
        >.txt {
            >.name {
                @include f-size(18);
                color: $keybb;
                @include mq(pc) {
                    @include f-size(16); } }
            >.caption {
                margin-bottom: 0; } }
        @for $i from 1 through 9 {
            &:nth-of-type(#{$i}) {
                >.txt {
                    >.name {
                        position: relative;
                        padding-left: 45px;
                        padding-top: 7px;
                        @include mq(pc) {
                            padding-top: 5px;
                            padding-left: 35px; }
                        &:before {
                            content: "#{$i}";
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 0px 10px;
                            color: #fff;
                            @include f-size(26);
                            display: inlne-block;
                            width: 37px;
                            background-color: $keybb;
                            box-sizing: border-box;
                            text-align: center;
                            @include mq(pc) {
                                @include f-size(20);
                                padding: 0px 9px;
                                width: 30px; } } } } } } } }

.eventList {
    display: flex;
    flex-wrap: wrap;
    >li {
        width: calc((100% - 40px) / 2);
        @include mq(maxTb) {
            width: calc((100% - 15px) / 2); }
        &:nth-of-type(2n) {
            margin-left: 40px;
            @include mq(maxTb) {
                margin-left: 15px; } }
        &:nth-of-type(n + 3) {
            margin-top: 40px;
            @include mq(maxTb) {
                margin-top: 30px; } }
        >a {
            flex-wrap: wrap;
            display: flex;
            transition: 0.5s;
            width: 100%;
            &:hover, &:focus {
                opacity: 0.5;
                text-decoration: none;
                >.img {
                    >.imgOuter {
                        >.img {
                            >.imgBg {
                                width: 105%;
                                height: 105%; } } } }
                >.txt {} }
            >.img {
                width: 250px;
                @include mq(minPcLimit) {
                    width: 40%; }
                @include mq(pc) {
                    width: 40%; }
                @include mq(maxTb) {
                    width: 100%; }
                .imgOuter {
                    background-image: repeating-linear-gradient(-45deg, #e6e6e6, #e6e6e6 6px, #fff 0, #fff 8px);
                    padding: 10px;
                    position: relative;
                    @include mq(maxTb) {
                        width: 100%;
                        margin: 0 auto; }
                    .img {
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        &:before {
                            content: "";
                            padding-top: 100%;
                            display: block; }
                        >.imgBg {
                            width: 100%;
                            height: 100%;
                            background-size: cover;
                            position: absolute;
                            background-position: center center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: 0.5s; } } } }
            >.txt {
                width: calc(100% - 250px);
                padding-left: 30px;
                @include mq(minPcLimit) {
                    width: 60%; }
                @include mq(pc) {
                    width: 60%; }
                @include mq(maxTb) {
                    width: 100%;
                    margin-top: 20px;
                    padding-left: 0; }
                >p {
                    margin: 0; }
                >p + p {
                    margin-top: 1em;
                    @include mq(maxTb) {
                        margin-top: 10px; } } } } }
    &.-free {
        >li {
            >a {
                >.img {
                    .imgOuter {
                        background-image: repeating-linear-gradient(-45deg, #d0e9f5, #d0e9f5 6px, #fff 0, #fff 8px);
                        .img {
                            &:before {
                                padding-top: 72%; } } } } } } } }


.flowList {
    display: flex;
    >li {
        background-color: #f2f2f2;
        border: 1px solid #999;
        padding: 10px;
        width: calc((100% - 130px) / 3);
        margin-right: 65px;
        text-align: center;
        @include f-size(18);
        position: relative;
        @include mq(maxTb) {
            width: calc((100% - 60px) / 3);
            margin-right: 30px;
            @include f-size(14); }
        @include mq(sp) {
            width: calc((100% - 15px) / 3);
            margin-right: 15px;
            padding: 5px; }
        &.-active {
            background-color: $keybb;
            color: #fff;
            border: 1px solid $keybb;
            &:after {
                color: $keyb; } }
        &:after {
            content: "\f284";
            font-family: ionicons;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(100%, -50%);
            @include f-size(26);
            margin-right: -33px;
            @include mq(maxTb) {
                margin-right: -15px; }
            @include mq(sp) {
                margin-right: -7px;
                @include f-size(18); } }
        &:nth-of-type(3) {
            margin-right: 0;
            &:after {
                display: none; } }
        p {
            margin: 0; } } }

.flowList + p {
    margin: 60px 0 40px;
    @include mq(maxTb) {
        margin: 30px 0 30px; } }

.flowList + .stripeTtl {
    margin: 60px 0 0;
    @include mq(maxTb) {
        margin: 30px 0 0; } }

.sitemapList {
    width: 100%;
    display: flex;
    margin: 60px 0 0 0;
    @include mq(maxTb) {
        flex-wrap: wrap; }
    >li {
        width: calc((100% - 180px) / 4);
        @include mq(minPcLimit) {
            width: calc((100% - 90px) / 4); }
        @include mq(pc) {
            width: calc((100% - 90px) / 4); }
        @include mq(maxTb) {
            width: 100%; }
        >ul {
            margin: 0;
            >li {
                border-bottom: 1px solid $keylb;
                font-weight: bold;
                @include f-size(18);
                position: relative;
                @include mq(pc) {
                    @include f-size(14); }
                @include mq(maxTb) {
                    width: 100%; }
                >a {
                    text-decoration: none;
                    color: $keyb;
                    padding: 15px 0 15px 1em;
                    transition: 0.5s;
                    display: inline-block;
                    width: 100%;
                    @include mq(maxTb) {
                        padding: 10px 10px 10px calc(0.5em + 10px); }
                    &:hover, &:focus {
                        opacity: 0.5; }
                    &:before {
                        content: "\f284";
                        font-family: ionicons;
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin-top: calc(0.5em + 10px);
                        line-height: 1;
                        @include mq(maxTb) {
                            left: 0.5em;
                            margin-top: calc(0.5em + 5px); } } } } } }

    >li + li {
        margin-left: 60px;
        @include mq(minPcLimit) {
            margin-left: 30px; }
        @include mq(pc) {
            margin-left: 30px; }
        @include mq(maxTb) {
            margin-left: 0; } } }

.privacyLink {
    list-style-type: none;
    margin: 60px 0 0;
    padding: 0;
    @include mq(maxTb) {
        margin-top: 30px; }
    >h3 {
        margin: 0; }

    >li + li {
        margin-top: 60px;
        @include mq(maxTb) {
            margin-top: 30px; } }

    >li {
        p + h4 {
            margin-top: 60px;
            @include mq(maxTb) {
                margin-top: 30px; }
            @include mq(maxTb) {
                margin-top: 20px; } } } }

