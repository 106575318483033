@charset "utf-8";

form {
    fieldset {
        border: none;
        padding: 0;
        margin: 0;
        p.errorTxt {
            color: #cc0101; } }
    legend {
        display: none; } }

.flowList + form {
    margin: 60px 0 0;
    @include mq(maxTb) {
        margin: 30px 0 0; } }

.check {
    display: inline-block;
    >input[type="checkbox"] {
        display: none; }

    &.-location {
        background-color: #d0e9f5;
        border: 1px solid #1ebee3;
        width: 100%;
        padding: 5px;
        box-sizing: border-box;
        @include mq(maxTb) {
            width: auto; } }

    label {
        box-sizing: border-box;
        transition: background-color 0.2s linear;
        display: inline-block;
        padding: 5px 5px 5px 30px;
        vertical-align: middle;
        cursor: pointer;
        position: relative;
        transition: 0.5s;
        &:hover, &:focus {
            opacity: 0.5; }

        &:before {
            transform: rotate(45deg);
            transition: opacity 0.2s linear;
            position: absolute;
            top: 50%;
            left: 11px;
            display: block;
            margin-top: -9px;
            width: 5px;
            height: 9px;
            border-right: 3px solid #333333;
            border-bottom: 3px solid #333333;
            content: '';
            opacity: 0;
            z-index: 1; }

        &:after {
            transition: border-color 0.2s linear;
            position: absolute;
            top: 50%;
            left: 5px;
            display: block;
            margin-top: -11px;
            width: 16px;
            height: 16px;
            border: 2px solid #070002;
            content: '';
            background-color: #fff; } }

    >input[type="checkbox"]:checked + label {
        &:before {
            opacity: 1; } } }

.inputText {
    display: inline-block;
    input[type="text"] {
        border: 1px solid $keybb;
        padding: 10px;
        width: 100%; } }


.select {
    @include f-size(16);
    display: inline-block;
    @include mq(sp) {
        display: block; }
    label {
        font-weight: bold;
        margin-right: 5px;
        vertical-align: middle;
        @include mq(maxTb) {
            display: block;
            margin-right: 0;
            margin-bottom: 5px; } }
    .position {
        display: inline-block;
        position: relative;
        &:after {
            position: absolute;
            content: "\f280";
            font-family: ionicons;
            right: 10px;
            top: 50%;
            @include f-size(25);
            transform: translateY(-50%);
            line-height: 0;
            pointer-events: none; } }
    select {
        appearance: none;
        border-radius: 0;
        border: 2px solid $keyb;
        margin: 0;
        padding: 5px 30px 5px 5px;
        background: none transparent;
        vertical-align: middle;
        box-sizing: content-box;
        width: 6em;
        &::-ms-expand {
            display: none; } } }

.inputTxt {
    display: flex;
    @include mq(maxPc) {
        display: block; }
    .input {
        width: 340px;
        @include mq(maxPc) {
            width: 100%;
            max-width: 570px; }
        input[type="text"],input[type="tel"],input[type="email"] {
            width: 100%;
            border: 1px solid #333;
            padding: 10px;
            @include mq(maxTb) {
                border-radius: 0;
                -webkit-appearance: none;
                @include f-size(16); }
            &.-middle {
                max-width: 180px; }
            &.-long {
                max-width: 570px; }
            &.-short {
                max-width: 100px; }
            &.-tel {
                max-width: 140px; } }
        &.-name {
            width: calc(100% - 10em);
            display: flex;
            max-width: 446px;
            @include mq(maxPc) {
                width: 100%;
                max-width: 446px; }
            >.left {
                width: 210px;
                margin-right: 30px;
                @include mq(maxPc) {
                    width: calc(50% - 15px); }
                @include mq(sp) {
                    width: calc(50% - 7px);
                    margin-right: 14px; }
                label {
                    margin-right: 5px;
                    display: inline-block; }
                input[type="text"] {
                    width: calc(100% - 1em - 10px); } }

            >.right {
                width: calc(100% - 240px);
                @include mq(maxPc) {
                    width: calc(50% - 15px); }
                @include mq(sp) {
                    width: calc(50% - 7px); }
                label {
                    margin-right: 5px;
                    display: inline-block; }
                input[type="text"] {
                    width: calc(100% - 1em - 10px); } } }
        &.-mail {
            max-width: 570px;
            width: calc(100% - 10em);
            display: flex;
            @include mq(maxPc) {
                width: 100%;
                max-width: 570px; }
            >.left {
                width: 210px;
                margin-right: 30px;
                @include mq(maxPc) {
                    width: calc(40% - 15px); }
                @include mq(sp) {
                    width: calc(40% - 7px);
                    margin-right: 14px; }
                label {
                    margin-right: 5px;
                    display: inline-block; }
                input[type="text"] {
                    width: calc(100% - 1em - 10px); } }
            >.right {
                width: calc(100% - 240px);
                @include mq(maxPc) {
                    width: calc(60% - 15px); }
                @include mq(sp) {
                    width: calc(60% - 7px); }
                label {
                    margin-right: 5px;
                    display: inline-block; }
                input[type="text"] {
                    width: calc(100% - 1em - 10px); } } }
        &.-tel {
            width: 500px;
            display: flex;
            @include mq(maxPc) {
                max-width: 500px;
                width: 100%; }
            .left {
                margin-right: 60px;
                position: relative;
                @include mq(maxTb) {
                    margin-right: 30px; }
                @include mq(sp) {
                    margin-right: 14px; }
                &:after {
                    content: "-";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(100%, -50%);
                    @include f-size(24);
                    margin-right: -25px;
                    @include mq(maxTb) {
                        margin-right: -10px; }
                    @include mq(sp) {
                        margin-right: -3px;
                        @include f-size(18); } } }
            .center {
                margin-right: 60px;
                position: relative;
                @include mq(maxTb) {
                    margin-right: 30px; }
                @include mq(sp) {
                    margin-right: 14px; }
                &:after {
                    content: "-";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(100%, -50%);
                    @include f-size(24);
                    margin-right: -25px;
                    @include mq(maxTb) {
                        margin-right: -10px; }
                    @include mq(sp) {
                        margin-right: -3px;
                        @include f-size(18); } } } }
        &.-url {
            width: 570px;
            @include mq(maxPc) {
                width: 100%;
                max-width: 570px; } } }
    .example {
        p {
            margin: 10px 0 0 10px;
            @include mq(maxPc) {
                margin: 10px 0 0 0; }
            @include mq(maxTb) {
                margin: 5px 0 0 0; } } } }
.exampleTxt {
    margin: 10px 0 0 0;
    @include mq(maxTb) {
        margin: 5px 0 0 0; } }

.submitArea {
    text-align: center;
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 30px; }
    &.-notFound {
        >ul {
            max-width: 480px;
            >li {
                .lineBtn {
                    max-width: 240px; } } } }
    .lineBtn {
        max-width: 220px;
        cursor: pointer;
        width: 100%;
        @include mq(maxTb) {
            max-width: inherit; } }
    >ul {
        display: flex;
        max-width: 470px;
        margin: 0 auto;
        @include mq(maxTb) {
            display: block; }
        >li {
            width: calc(50% - 15px);
            @include mq(maxTb) {
                width: 100%; }
            &:nth-of-type(2) {
                margin-left: 30px;
                @include mq(maxTb) {
                    margin-left: 0;
                    margin-top: 15px; } } } } }

textarea {
    resize: vertical;
    width: 100%;
    height: 200px;
    border: 1px solid #333;
    vertical-align: bottom;
    @include mq(maxTb) {
        height: 100px;
        border-radius: 0;
        -webkit-appearance: none;
        @include f-size(16); } }

.searchArea {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(sp) {
        flex-wrap: wrap; }
    input[type="text"] {
        border: 1px solid #000;
        padding: 10px;
        width: 410px;
        height: 40px;
        box-sizing: border-box;
        @include mq(maxTb) {
            width: calc(100% - 79px);
            border-radius: 0;
            -webkit-appearance: none;
            @include f-size(16);
            height: 38px; }
        @include mq(sp) {
            width: 100%; } }
    .submit {
        >.btn {
            position: relative;
            height: 100%;
            background-color: #000;
            color: #fff;
            padding: 5px 20px 5px 30px;
            height: 100%;
            border: 1px solid #000;
            border-left: 0 solid #000;
            transition: 0.5s;
            height: 40px;
            @include mq(maxTb) {
                height: 38px; }
            @include mq(sp) {
                margin-top: 20px;
                border-left: 1px solid #000; }
            &:before {
                content: "\f284";
                font-family: "ionicons";
                position: absolute;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
                color: #fff;
                transition: 0.5s; }
            &:hover, &:focus {
                color: #000;
                cursor: pointer;
                background-color: #fff;
                &:before {
                    color: #000; } } } } }
