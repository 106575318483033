@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic-Regular"); }

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local("YuGothic-Bold"), local("Yu Gothic"); }

$font-serif: 'Noto Serif Japanese', 游明朝, Yu Mincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', ' HGS明朝E' , 'HG明朝E', 'MS P明朝',MS PMincho,'MS 明朝', Georgia, "Times New Roman", Times, serif !default;

$font-sans-serif: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI","Noto Sans Japanese", "Noto Sans CJK JP", -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", MyYuGothicM, Yu Gothic, メイリオ, Meiryo,  sans-serif !default;

$font-sans-serif-hiragino: -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", MyYuGothicM, Yu Gothic, メイリオ, Meiryo, "Noto Sans Japanese", "Noto Sans CJK JP", sans-serif !default;

$font-sans-serif-yu: -apple-system, BlinkMacSystemFont, 游ゴシック, "Yu Gothic", Yu Gothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, "Noto Sans Japanese", "Noto Sans CJK JP", sans-serif !default;

$font-mono: Menlo, Monaco, Consolas, 'Courier New', monospace !default;

$font-tsukushi: 'Tsukushi A Round Gothic', '筑紫A丸ゴシック', "ヒラギノ丸ゴ ProN","Hiragino Maru Gothic ProN", -apple-system, BlinkMacSystemFont, Meiryo, メイリオ !default;

$font-ie: "Helvetica", Meiryo, sans-serif;

body {
    font-family: $font-sans-serif; }
