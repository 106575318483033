@charset "utf-8";

.waveTtl {
    font-weight: bold;
    @include f-size(34);
    text-align: center;
    @include mq(maxTb) {
        @include f-size(24); }
    &.-pc {
        @include mq(maxTb) {
            display: none; } }
    &.-short {
        @include f-size(26);
        @include mq(maxTb) {
            @include f-size(24); } }
    &.-company {
        text-align: left; }
    &.-long {
        text-align: left;
        >.inner {
            width: 100%!important; } }
    &.-spLong {
        >.inner {
            @include mq(maxTb) {
                width: 100%!important; } } }

    >.inner {
        display: inline-block;
        position: relative;
        padding-bottom: 10px;
        max-width: 100%;
        &:after {
            position: absolute;
            content: "";
            width: 100%;
            bottom: 0;
            left: 0;
            height: 11px;
            background-size: 44px 11px;
            background-repeat: repeat;
            background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" id="レイヤー_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45.1 9.4" style="enable-background:new 0 0 45.1 9.4;" xml:space="preserve"><style type="text/css">.st0{fill:' + $keyly + ';}</style><path class="st0" d="M0,6.5V4.4c0,0,6.8-2.3,10.5-3.2s8.4-1.8,13.3-0.7s11.8,3.8,14.7,4.1c2.8,0.2,4.4,0.1,5.3,0c0.9-0.1,1.2-0.2,1.2-0.2v2.1c0,0-7.1,2.3-11.1,2.8c-4.3,0.5-10.9-0.7-12.7-1.1c-1.8-0.4-7.9-2.5-13-2.7C5.7,5.4,0.8,6.3,0,6.5z"/></svg>');
            .firefox & {
                mask-image: url(/assets/img/cmn/wave.svg);
                background-color: $keyly;
                mask-repeat: repeat-x;
                mask-position: left;
                mask-size: 43px 11px; }
            .ie & , .edge & {
                background-size: 44px 10px;
                height: 10px;
                background-image: url('/assets/img/cmn/wave_yellow.png');
                background-repeat: round; } }

        span {
            @include f-size(24);
            font-weight: normal;
            @include mq(maxTb) {
                @include f-size(16); } }
        .inline {
            margin: 0; } }
    &.-white {
        color: #fff;
        .inner {
            &:after {
                background-image: url('data:image/svg+xml;charset=utf8,<svg version="1.1" id="レイヤー_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45.1 9.4" style="enable-background:new 0 0 45.1 9.4;" xml:space="preserve"><style type="text/css">.st0{fill:#fff;}</style><path class="st0" d="M0,6.5V4.4c0,0,6.8-2.3,10.5-3.2s8.4-1.8,13.3-0.7s11.8,3.8,14.7,4.1c2.8,0.2,4.4,0.1,5.3,0c0.9-0.1,1.2-0.2,1.2-0.2v2.1c0,0-7.1,2.3-11.1,2.8c-4.3,0.5-10.9-0.7-12.7-1.1c-1.8-0.4-7.9-2.5-13-2.7C5.7,5.4,0.8,6.3,0,6.5z"/></svg>');
                .firefox & {
                    mask-image: url(/assets/img/cmn/wave.svg);
                    background-color: #fff;
                    mask-repeat: repeat-x;
                    mask-position: left;
                    mask-size: 43px 11px; }
                .ie & , .edge & {
                    background-image: url('/assets/img/cmn/wave_white.png'); } } } }
    &.-search {
        margin-top: 20px;
        @include mq(maxTb) {
            margin-top: 40px; }
        .inner {
            .inline {
                span {} } } } }


.waveTtl + * {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 40px; } }

.waveTtl.-pc + * {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 0; } }

.lineTtl {
    text-align: center;
    position: relative;
    .line {
        display: inline-block;
        position: absolute;
        background-color: $keylb;
        height: 10px;
        top: 50%;
        transform: translateY(-50%);
        @include mq(maxTb) {
            height: 5px; }
        &.-left {
            left: 0;
            .arrow {
                width: 100%;
                position: relative;
                &:before {
                    position: absolute;
                    right: -20px;
                    top: 0;
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 20px 0 0;
                    border-color: $keylb transparent transparent transparent;
                    @include mq(maxTb) {
                        border-width: 5px 10px 0 0;
                        right: -10px; } } } }
        &.-right {
            right: 0;
            .arrow {
                width: 100%;
                position: relative;
                &:before {
                    position: absolute;
                    left: -20px;
                    top: 0;
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 0 10px 20px;
                    border-color: transparent transparent $keylb transparent;
                    @include mq(maxTb) {
                        left: -10px;
                        border-width: 0 0 5px 10px; } } } } }
    .inline {
        color: $keybb;
        display: inline-block;
        @include f-size(26);
        padding: 0 30px;
        margin: 0;
        @include mq(maxTb) {
            @include f-size(19);
            padding: 0 15px; }
        @include mq(sp) {
            max-width: 60%;
            text-align: left; } } }


* + .lineTtl {
    margin-top: 70px;
    @include mq(maxTb) {
        margin-top: 40px; } }

.lineTtl + * {
    margin-top: 40px;
    @include mq(maxTb) {
        margin-top: 30px; } }

.companiesTtl {
    background-color: $keybb;
    color: #fff;
    @include f-size(24);
    padding: 15px;
    font-weight: normal;
    margin: 0;
    width: 250px;
    text-align: center;
    position: relative;
    @include mq(maxTb) {
        width: 100%;
        padding: 10px;
        @include f-size(16); }
    span {
        @include mq(maxTb) {
            position: relative; }
        &:before {
            content: "";
            width: 34px;
            height: 35px;
            display: inline-block;
            background-image: url('/assets/img/search/conditions/ttl.jpg');
            background-size: cover;
            position: absolute;
            transform: translateY(-50%);
            left: 15px;
            top: 50%;
            @include mq(maxTb) {
                left: 0;
                width: 24px;
                height: 25px;
                transform: translate(-100%, -50%);
                margin-left: -15px; } } } }

.circleTtl {
    text-align: center;
    @include f-size(30);
    margin: 0;
    @include mq(maxTb) {
        @include f-size(21);
        text-align: left;
        padding: 20px; }
    .circleTxt {
        position: relative;
        color: #fff;
        @include f-size(38);
        margin-right: 15px;
        z-index: 1;
        @include mq(maxTb) {
            @include f-size(26);
            margin-right: 10px; }
        &:before {
            content: "";
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            background-color: $keybb;
            z-index: -1; } } }

.circleTtl + * {
    margin-top: 50px; }

.stripeTtl {
    background-image: repeating-linear-gradient(-45deg, #d0e9f5, #d0e9f5 6px, #fff 0, #fff 8px);
    color: $keybb;
    @include f-size(24);
    padding: 20px;
    margin: 0;
    @include mq(maxTb) {
        @include f-size(17);
        padding: 10px 15px; } }

.stripeTtl + * {
    margin-top: 30px;
    @include mq(maxTb) {
        margin-top: 20px; } }

.borderTtl {
    @include f-size(20);
    padding-bottom: 10px;
    border-bottom: 4px solid #1ebee3;
    margin: 0;
    @include mq(maxTb) {
        @include f-size(16); } }

.borderTtl02 {
    @include f-size(18);
    padding-bottom: 10px;
    border-bottom: 2px solid #999;
    margin: 0;
    @include mq(maxTb) {
        @include f-size(15); } }

.borderTtl + * {
    margin-top: 30px;
    @include mq(maxTb) {
        margin-top: 20px; } }

.waveTtl + .borderTtl {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 30px; } }

p + .borderTtl {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 30px; } }

.borderTtl02 + * {
    margin-top: 30px;
    @include mq(maxTb) {
        margin-top: 20px; } }
