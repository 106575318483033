@charset "utf-8";

body {
    animation: fadeIn 2s ease 0s 1 normal;
    -webkit-animation: fadeIn 2s ease 0s 1 normal;
    @keyframes fadeIn {
        0% {
            opacity: 0; }
        100% {
            opacity: 1; } }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0; }
        100% {
            opacity: 1; } }
    &.active {
        @include mq(maxTb) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden; } } }

.layout {
    width: 100%;
    overflow: hideen;
    .layoutCont {} }

.main {}

.cont {
    max-width: $maxWidth;
    margin: 0 auto;
    box-sizing: content-box;
    padding: 0 20px;
    &.-pageBottom {
        padding-bottom: 80px;
        @include mq(maxTb) {
            padding-bottom: 40px; } }
    &.-campany {
        @include mq(maxTb) {
            padding: 0; } } }

.pankuzu + .cont {
    margin-top: 50px;
    @include mq(maxTb) {
        margin-top: 40px; } }

.lineTtl + .cont {
    margin-top: 40px;
    @include mq(maxTb) {
        margin-top: 30px; } }

.newsArea {
    background-image: url('/assets/img/cmn/bg_blue.jpg');
    .btnArea {
        max-width: 335px;
        width: 100%;
        margin: 0 auto; }
    .newsList + .btnArea {
        margin-top: 70px;
        @include mq(maxTb) {
            margin-top: 40px; } } }


.cont + .newsArea {
    margin-top: 70px;
    padding: 70px 0;
    @include mq(maxTb) {
        margin-top: 40px;
        padding: 40px 0; } }

.aboutArea {
    background-image: url('/assets/img/cmn/bg_square.jpg');
    position: relative;
    >.bg {
        background-image: url('/assets/img/top/bg02.png'),url('/assets/img/top/bg.png');
        background-position: bottom left, top left;
        background-size: 100% auto, 100% auto;
        background-repeat: no-repeat, no-repeat;
        padding: 70px 0 140px;
        @include mq(maxTb) {
            background-image: none;
            padding: 40px 0 45px; } } }

.bnrArea {
    background-color: #fff;
    margin-top: -20px;
    padding-bottom: 40px;
    @include mq(maxTb) {
        margin-top: -20px; } }
