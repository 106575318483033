@charset "utf-8";

.slidArea {
    width: 100%;
    margin-top: 130px;
    @include mq(pcWide) {
        margin-top: 60px; }
    @include mq(maxTb) {
        margin-top: 30px; }
    >h3 {
        font-weight: bold;
        text-align: center;
        @include f-size(32);
        margin: 0;
        @include mq(maxTb) {
            @include f-size(23); }
        .circleTxt {
            position: relative;
            color: #fff;
            @include f-size(36);
            margin-right: 10px;
            z-index: 1;
            @include mq(maxTb) {
                @include f-size(26); }
            &:before {
                content: "";
                position: absolute;
                width: 1.5em;
                height: 1.5em;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                background-color: $keybb;
                z-index: -1; } } }

    .waveTtl + h3 {
        margin-top: 15px; }

    .slideOuter {
        margin-top: 50px;
        position: relative;
        @include mq(maxTb) {
            margin-top: 10px; }
        &:before {
            width: 100%;
            background-color: $keylb;
            height: 40%;
            display: inline-block;
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            transform: translateY(-50%); }
        .slick-arrow {
            display: none!important; }
        .slickPrev {
            position: absolute;
            top: 55%;
            left: -48px;
            z-index: 1;
            background-color: #fff;
            border: 0 solid transparent;
            color: $keybb;
            @include f-size(38);
            border-radius: 50%;
            padding: 1px 17px 1px 14px;
            display: inline-block;
            transform: translateY(-50%);
            line-height: 1.0;
            transition: 0.5s;
            @include mq(pcWide) {
                border-top-left-radius: 75px;
                border-bottom-left-radius: 75px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: 10px 12px 10px 15px;
                left: -37px; }
            @include mq(maxTb) {
                border-top-left-radius: 75px;
                border-bottom-left-radius: 75px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: 10px 10px 10px 10px;
                left: -29px; }
            &:hover, &:focus {
                color: #fff;
                background-color: $keybb;
                outline: none;
                cursor: pointer; } }
        .slickNext {
            position: absolute;
            top: 55%;
            right: -48px;
            z-index: 1;
            background-color: #fff;
            border: 0 solid transparent;
            color: $keybb;
            @include f-size(38);
            border-radius: 50%;
            padding: 1px 14px 1px 17px;
            transform: translateY(-50%);
            line-height: 1.0;
            outline: none;
            transition: 0.5s;
            @include mq(pcWide) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 75px;
                border-bottom-right-radius: 75px;
                padding: 10px 15px 10px 12px;
                right: -37px; }
            @include mq(maxTb) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 75px;
                border-bottom-right-radius: 75px;
                padding: 10px 10px 10px 10px;
                right: -29px; }
            &:hover, &:focus {
                color: #fff;
                background-color: $keybb;
                cursor: pointer; } }
        .slideBox {
            max-width: 1540px;
            width: calc(100% - 112px);
            margin: 0 auto;
            position: relative;
            @media only screen and (max-width: 1540px) {
                margin: 0 56px; }
            @include mq(pcWide) {
                margin: 0 56px; }
            @include mq(maxTb) {
                margin: 0 35px;
                width: calc(100% - 70px); } }
        .slideCont {
            width: 100%;
            overflow: hidden; }
        ul.slide {
            width: calc(100% + 48px);
            margin: 0 auto;
            transform: translateX(-24px);
            display: none;
            opacity: 0;
            transition: opacity .3s linear;
            &.slick-initialized {
                display: block;
                opacity: 1; }
            @include mq(maxTb) {
                width: calc(100% + 28px);
                transform: translateX(-14px); }
            .slick-list {
                margin: 0 15px; }
            li {
                width: calc(100% / 5); }

            a {
                text-decoration: none;
                color: #fff;
                background-color: $keylb;
                margin: 0 12px;
                padding: 8px;
                box-sizing: border-box;
                width: calc(100% - 24px);
                display: inline-block;
                @include mq(maxTb) {
                    margin: 0 7px;
                    width: calc(100% - 14px); }
                .imgOuter {
                    padding: 17px 16px;
                    background-color: #fff;
                    .img {
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        &:before {
                            content: "";
                            padding-top: 100%;
                            display: block; }
                        >.imgBg {
                            width: 100%;
                            height: 100%;
                            background-size: cover;
                            position: absolute;
                            background-position: center center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: 0.5s; } } }

                .txt {
                    background-color: $keybb;
                    padding: 10px 30px 10px 35px;
                    transition: 0.5s;
                    @include mq(maxTb) {
                        @include f-size(14);
                        padding: 10px 10px 10px 25px; }
                    p {
                        margin: 0; }
                    .name {
                        position: relative;
                        &:before {
                            content: "\f284";
                            font-family: ionicons;
                            position: absolute;
                            top: 0;
                            left: -10px;
                            transform: translateX(-50%); } } }

                &:hover, &:focus {
                    color: $keybb;
                    .imgOuter {
                        .img {
                            >.imgBg {
                                width: 105%;
                                height: 105%; } } }
                    .txt {
                        background-color: #fff; } } } }

        >.buttonArea {
            width: 100%;
            margin: 0 auto;
            text-align: right;
            padding: 0 56px;
            margin-top: 20px;
            max-width: 1653px;
            @include mq(maxTb) {
                padding: 0 42px; } } }

    #stop {
        color: #a19e9e;
        border: 1px solid #a19e9e;
        background-color: #fff;
        padding: 5px 15px;
        @include f-size(16);
        transition: 0.5s;
        &:hover,&:focus {
            cursor: pointer;
            color: #fff;
            background-color: #a19e9e; }
        .-hide {
            display: none; } } }
