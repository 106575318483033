@charset "utf-8";

.mv {
    position: relative;
    >.bg {
        background-image: url('/assets/img/cmn/bg.jpg');
        background-repeat: repeat;
        display: inline-block;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0; }
    >.inner {
        position: relative;
        background-image: url('/assets/img/top/mv_pc.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        @include mq(pcWide) {
            background-size: 125% auto;
            background-position: top center; }
        @include mq(maxTb) {
            background-image: none; }
        &:before {
            content: "";
            display: block;
            padding-top: 15%;
            @include mq(pcWide) {
                padding-top: 18%; }
            @include mq(maxTb) {
                display: none; } }
        .ttl {
            margin: 0 auto;
            @include f-size(14);
            text-align: center;
            width: 60%;
            padding-right: 6%;
            @media only screen and (max-width: 1540px) {
                width: 45%; }
            @include mq(pcWide) {
                width: 60%; }
            @include mq(maxTb) {
                width: 100%;
                padding-right: 0; }
            h2 {
                margin: 0;
                img {
                    width: 100%;
                    @include mq(maxTb) {
                        max-width: inherit; }
                    &:nth-of-type(1) {
                        max-width: 732px;
                        @include mq(maxTb) {
                            display: none; } }
                    &:nth-of-type(2) {
                        display: none;
                        @include mq(maxTb) {
                            display: inline; } } } }
            >p {
                @include f-size(16);
                @media only screen and (max-width: 1540px) {
                    text-align: left; }
                @include mq(pcWide) {
                    text-align: left; }
                @include mq(maxTb) {
                    padding: 20px 20px 0 20px;
                    margin: 0;
                    @include f-size(14);
                    text-align: left; } } } } }
