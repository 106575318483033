@charset "utf-8";

$width-scrollbar: 20px;
$break-wide: 1200px;
$break-pc: 986px;
$break-tb: 768px;
$break-sp: 480px;

@mixin mq($point) {
  @if $point == minSp {
    @media print, screen and (min-width: $break-sp + 1) {
      @content; } }
  @else if $point == minTb {
    @media print, screen and (min-width: $break-tb + 1) {
      @content; } }
  @else if $point == minPc {
    @media print, screen and (min-width: $break-pc + 1) {
      @content; } }
  @else if $point == maxTb {
    @media only screen and (max-width: $break-tb) {
      @content; } }
  @else if $point == maxPc {
    @media only screen and (max-width: $break-pc) {
      @content; } }
  @else if $point == maxWide {
    @media only screen and (max-width: $break-wide) {
      @content; } }
  @else if $point == wide {
    @media print, screen and (min-width: $break-wide + 1) {
      @content; } }
  @else if $point == minPcLimit {
    @media only screen and (min-width: $break-pc + 1) and (max-width: $break-wide) {
      @content; } }
  @else if $point == pc {
    @media only screen and (min-width: $break-tb + 1) and (max-width: $break-pc) {
      @content; } }
  @else if $point == pcWide {
    @media only screen and (min-width: $break-tb + 1) and (max-width: $break-wide) {
      @content; } }
  @else if $point == tb {
    @media only screen and (min-width: $break-sp + 1) and (max-width: $break-tb) {
      @content; } }
  @else if $point == tbWide {
    @media only screen and (min-width: $break-sp + 1) and (max-width: $break-wide) {
      @content; } }
  @else if $point == sp {
    @media only screen and (max-width: $break-sp) {
      @content; } }
  @else if $point == print {
    @media print {
      @content; } } }

@mixin e($name) {
  @at-root &__#{$name} {
    @content; } }

@mixin m($name) {
  @at-root &--#{$name} {
    @content; } }

@mixin f-size($size) {
    font-size: $size + px;
    font-size: ($size / 10) + rem; }

@mixin variable-box($percent) {
  position: relative;
  &:after {
    content: " ";
    display: block;
    padding-top: $percent + %; }
  @include e(inner) {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; } }
