@charset "utf-8";

.centerTxt {
    text-align: center; }

.aboutBlock {
    display: flex;
    position: relative;
    @include mq(maxTb) {
        display: block; }
    >.txt {
        width: 62%;
        padding-right: 60px;
        box-sizing: border-box;
        @include mq(minPcLimit) {
            width: 60%;
            padding-right: 30px; }
        @include mq(pc) {
            width: 55%;
            padding-right: 30px; }
        @include mq(maxTb) {
            width: 100%;
            padding-right: 0; }
        >p {
            margin: 0;
            @include f-size(18);
            line-height: 1.8;
            @include mq(maxTb) {
                @include f-size(14); }
            >span {
                @include f-size(25);
                font-weight: bold;
                @include mq(maxTb) {
                    @include f-size(18); } }
            >strong {
                @include f-size(24);
                @include mq(maxTb) {
                    @include f-size(16); } } }
        p + p {
            margin-top: 60px;
            @include mq(maxTb) {
                margin-top: 20px; } } }

    >.img {
        width: 38%;
        @include mq(minPcLimit) {
            width: 40%; }
        @include mq(pc) {
            width: 45%; }
        @include mq(maxTb) {
            width: 100%;
            margin-top: 20px; }
        >a {
            padding: 10px;
            background-color: $keylb;
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            color: $keyb;
            text-decoration: none;
            transition: 0.5s;
            &:hover, &:focus {
                color: #fff;
                >h3 {
                    background-color: $keylb;
                    color: #fff; }
                >p {
                    background-color: $keylb;
                    >span {
                        &:before {
                            color: #fff; } } }
                >.img {
                    >.imgBg {
                        width: 105%;
                        height: 105%; } } }

            >.img {
                width: 100%;
                overflow: hidden;
                position: relative;
                &:before {
                    content: "";
                    padding-top: 56%;
                    display: block; }
                >.imgBg {
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transition: 0.5s; } }
            >h3 {
                color: $keybb;
                background-color: #fff;
                margin: 0;
                padding: 10px;
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                @include f-size(30);
                transition: 0.5s;
                @include mq(pcWide) {
                    @include f-size(28); }
                @include mq(maxTb) {
                    @include f-size(21); } }

            >p {
                background-color: #fff;
                margin: 0;
                padding: 20px 10px;
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                @include f-size(20);
                @include mq(maxTb) {
                    @include f-size(16); }
                >span {
                    padding-left: 4rem;
                    padding-right: 2rem;
                    position: relative;
                    @include mq(maxTb) {
                        padding-left: 3.5rem; }
                    &:before {
                        transition: 0.5s;
                        content: "\f34f";
                        color: #ff0000;
                        line-height: 1;
                        margin-top: 1px;
                        @include f-size(30);
                        font-family: "ionicons";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        @include mq(maxTb) {
                            @include f-size(25);
                            margin-top: 0; } }
                    &:after {
                        content: "\f33b";
                        font-family: "ionicons";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%); } } } } } }


.conditionsBlock {
    border-bottom: 1px dotted #000;
    @include mq(maxTb) {
        border-bottom: 0 dotted #000; }
    &.-search {
        padding-bottom: 30px;
        @include mq(maxTb) {
            padding-bottom: 0; } }
    &.-option {
        border-bottom: 1px solid #6d6a6a;
        padding-bottom: 30px;
        position: relative;
        &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 25px 30px 0;
            border-color: #6d6a6a transparent transparent transparent;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            position: absolute; } }
    &.open {
        >.head {
            >.ttl {
                .switch {
                    &:after {
                        content: "\f368";
                        .safari & {
                            content: "\f068";
                            font-family: "Font Awesome 5 Free"; } } } }
            >.headCont {
                @include mq(maxTb) {
                    max-height: 9999px;
                    border-bottom: 1px dotted #000;
                    padding: 20px 0 20px; }

                &.-search {
                    @include mq(maxTb) {
                        padding: 40px 0 40px; } } } }
        >.blockCont {
            @include mq(maxTb) {
                max-height: 9999px;
                padding: 20px 0; } } }
    >.head {
        display: flex;
        align-items: center;
        @include mq(maxTb) {
            display: block; }
        >.ttl {
            .switch {
                background-color: $keybb;
                border: 1px solid $keybb;
                color: #fff;
                padding: 0;
                position: relative;
                text-decoration: none;
                display: inline-block;
                text-align: center;
                @include mq(minTb) {
                    pointer-events: none;
                    width: 250px; }
                @include mq(maxTb) {
                    transition: 0.5s;
                    width: 100%;
                    &:hover, &:focus {
                        background-color: #fff;
                        color: $keybb;
                        cursor: pointer;
                        >.inner {
                            >svg {
                                fill: $keybb; } }
                        &:before {
                            @include mq(maxTb) {
                                background-color: $keybb; } }
                        &:after {
                            @include mq(maxTb) {
                                color: #fff; } } } }

                &:before {
                    content: "";
                    width: 27px;
                    height: 27px;
                    display: inline-block;
                    background-color: #fff;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    transition: 0.5s;
                    @include mq(minTb) {
                        display: none; } }
                &:after {
                    content: "\f273";
                    font-family: "ionicons";
                    position: absolute;
                    top: 50%;
                    right: 19px;
                    transform: translateY(-50%);
                    color: #333;
                    font-weight: bold;
                    transition: 0.5s;
                    .safari & {
                        content: "\f067";
                        font-family: "Font Awesome 5 Free";
                        right: 17px; }
                    @include mq(minTb) {
                        display: none; } }
                .inner {
                    position: relative;
                    @include mq(maxTb) {
                        display: inline-block; }
                    >.name {
                        @include f-size(24);
                        margin: 0;
                        padding: 20px;
                        @include mq(maxTb) {
                            @include f-size(19);
                            padding: 10px 20px; } }
                    >svg {
                        fill: #fff;
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        width: 28px;
                        transform: translateY(-50%);
                        transition: 0.5s;
                        @include mq(maxTb) {
                            transform: translate(-100%, -50%); } } }

                &.-industry {
                    >.inner {
                        >svg {
                            width: 30px; } } }
                &.-location {
                    >.inner {
                        >svg {
                            width: 30px; } } }
                &.-search {
                    >.inner {
                        >svg {
                            width: 35px; } } }
                &.-option {
                    >.inner {
                        >svg {
                            width: 32px; } } } } }
        >.headCont {
            @include mq(minTb) {
                width: calc(100% - 250px);
                padding-left: 20px; }
            @include mq(maxTb) {
                max-height: 0;
                transition: 0.5s;
                overflow: hidden;
                padding: 0; }
            >.inputText {
                max-width: 535px;
                width: 100%;
                input[type="text"] {
                    border-radius: 0;
                    -webkit-appearance: none;
                    @include f-size(16); } } } }

    >.blockCont {
        padding: 30px 30px 30px 60px;
        @include mq(maxTb) {
            padding: 0;
            max-height: 0;
            transition: 0.5s;
            overflow: hidden; }
        >table {
            tr {
                @include mq(maxTb) {
                    width: 100%;
                    display: block; }
                th {
                    width: 130px;
                    text-align: left;
                    font-weight: normal;
                    padding: 0;
                    vertical-align: top;
                    @include mq(maxTb) {
                        width: 100%;
                        display: block; } }
                td {
                    padding: 0 10px;
                    @include mq(maxTb) {
                        padding: 5px; }
                    >ul {
                        margin: 0;
                        >li {
                            display: inline-block;
                            margin-right: 30px;
                            @include mq(maxTb) {
                                margin-right: 20px; } } } } }
            tr + tr {
                th {
                    padding-top: 10px; }
                td {
                    padding-top: 10px; } } } }

    .industryBox {
        display: flex;
        @include mq(maxTb) {
            display: block; }
        .column {
            width: calc((100% - 60px) / 3);
            margin-right: 30px;
            &:nth-of-type(3) {
                margin-right: 0; }
            @include mq(maxTb) {
                width: 100%;
                margin-right: 0; }
            >ul {
                margin: 0; } } } }

fieldset + fieldset > .conditionsBlock {
    margin-top: 30px;
    @include mq(maxTb) {
        margin-top: 20px; } }

.displayTxt {
    margin: 40px 0 0;
    text-align: center;
    @include f-size(20);
    color: $keybb;
    font-weight: bold;
    @include mq(maxTb) {
        @include f-size(19); } }

.companyInfo {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    @include mq(minPc) {
        padding-left: 330px;
        margin-bottom: 60px; }
    @include mq(pc) {
        padding-left: calc(40% + 30px);
        margin-bottom: 60px; }
    @include mq(maxTb) {
        margin: 0 20px 20px;
        width: calc(100% - 40px); }

    .infoCont {
        @include mq(tb) {
            display: flex;
            margin-top: 20px; }
        @include mq(sp) {
            display: block;
            margin-top: 20px; }
        >.left {
            @include mq(minPc) {
                width: 300px;
                position: absolute;
                top: 0;
                left: 0; }
            @include mq(pc) {
                width: 40%;
                position: absolute;
                top: 0;
                left: 0; }
            @include mq(tb) {
                width: 40%; }
            >.imgOuter {
                background-image: repeating-linear-gradient(-45deg, #d0e9f5, #d0e9f5 6px, #fff 0, #fff 8px);
                padding: 10px;
                >.img {
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                    &:before {
                        content: "";
                        padding-top: 70%;
                        display: block; }
                    >.imgBg {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        position: absolute;
                        background-position: center center;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: 0.5s; } } } }

        >.right {
            @include mq(minPc) {
                width: 510px;
                box-sizing: border-box;
                margin-top: 30px; }
            @include mq(pc) {
                margin-top: 30px; }
            @include mq(tb) {
                width: 60%;
                box-sizing: border-box;
                padding-left: 20px; }
            @include mq(sp) {
                margin-top: 20px; }

            >table {
                >tbody {
                    >tr {
                        >th {
                            width: 4em;
                            >span {
                                padding: 5px;
                                background-color: $keybb;
                                color: #fff;
                                width: 100%;
                                text-align: center;
                                display: inline-block; } }
                        >td {
                            padding: 5px 0 0 5px;
                            font-weight: bold; } }
                    >tr + tr {
                        th {
                            padding-top: 10px; }
                        td {
                            padding-top: 10px; } } } } } } }


.campanyBlock {
    @include mq(minTb) {
        max-height: 0;
        overflow: hidden;
        padding: 0; }
    @include mq(maxTb) {
        padding-top: 0;
        display: block; }

    &.-intro {
        @include mq(maxTb) {
            border-bottom: 1px solid #999; } }

    &.-active {
        display: block;
        @include mq(minTb) {
            max-height: inherit;
            overflow: inherit;
            padding: 60px 20px 20px 0; }
        >.inner {
            @include mq(maxTb) {
                max-height: 9999px;
                padding: 0 20px 20px; } }
        .head {
            &:before {
                content: '閉じる';
                right: 19px; }
            &:after {
                content: '\f368';
                .safari & {
                    content: "\f068";
                    font-family: "Font Awesome 5 Free"; } } } }
    >.inner {
        @include mq(maxTb) {
            max-height: 0;
            padding: 0 20px;
            overflow: hidden;
            transition: 0.5s; } }
    .about {
        padding: 0 40px;
        box-sizing: border-box;
        @include mq(maxTb) {
            padding: 0 20px; }
        &.-border {
            padding: 40px;
            border: 1px solid #999;
            @include mq(maxTb) {
                padding: 20px; }
            >.inner {
                @include mq(maxTb) {
                    display: block; }
                >.img {
                    @include mq(maxTb) {
                        max-width: 240px;
                        width: 100%;
                        margin: 0 auto; }
                    @include mq(sp) {
                        max-width: inherit; } }
                >.txt {
                    @include mq(maxTb) {
                        width: 100%;
                        margin-top: 20px; } } } }
        >.inner {
            display: flex;
            @include mq(sp) {
                display: block; }
            >.img {
                width: 230px;
                @include mq(maxTb) {
                    width: 35%; }
                @include mq(sp) {
                    width: 100%;
                    max-width: inherit;
                    margin: 0 auto; }
                >.imgOuter {
                    background-image: repeating-linear-gradient(-45deg, #e6e6e6, #e6e6e6 6px, #fff 0, #fff 8px);
                    padding: 10px;
                    >.img {
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        &:before {
                            content: "";
                            padding-top: 90%;
                            display: block; }
                        >.imgBg {
                            width: 100%;
                            height: 100%;
                            background-size: cover;
                            position: absolute;
                            background-position: center center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: 0.5s; } } } }
            >.txt {
                width: calc(100% - 230px);
                padding-left: 30px;
                box-sizing: border-box;
                @include mq(maxTb) {
                    width: 65%;
                    padding-left: 15px; }
                @include mq(sp) {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 20px; }
                >p {
                    margin: 0; }
                >p + p {
                    margin-top: 1em; } } } }


    .head {
        background-color: transparent;
        border: 0px solid transparent;
        width: 100%;
        text-align: center;
        position: relative;
        @include mq(minTb) {
            pointer-events: none; }
        @include mq(maxTb) {
            text-align: left;
            border-top: 1px solid #999;
            cursor: pointer;
            .circleTtl {
                transition: 0.5s; }
            &:hover, &:focus {
                .circleTtl {
                    opacity: 0.5; }
                &:before {
                    opacity: 0.5; }
                &:after {
                    opacity: 0.5; } } }
        &:before {
            content: "開く";
            position: absolute;
            top: 10px;
            right: 25px;
            font-size: 8px;
            font-size: 1.2rem;
            font-weight: bold;
            @include mq(minTb) {
                display: none; } }
        &:after {
            content: "\f273";
            font-family: ionicons;
            position: absolute;
            top: 50%;
            right: 19px;
            transform: translateY(-30%);
            color: #fff;
            font-weight: bold;
            transition: 0.5s;
            padding: 8px 12px;
            background-color: #333;
            @include f-size(22);
            display: inline-block;
            line-height: 1;
            .safari & {
                content: "\f067";
                font-family: "Font Awesome 5 Free"; }
            @include mq(minTb) {
                display: none; } } }
    .head + * {
        margin-top: 50px;
        @include mq(maxTb) {
            margin-top: 0; } } }

.companyOverview {
    margin-top: 70px;
    @include mq(maxTb) {
        margin-top: 30px;
        padding: 0 20px; }
    .map {
        width: 100%;
        height: 560px;
        border: 1px solid #999;
        margin-top: 40px;
        @include mq(maxTb) {
            margin-top: 20px; } }

    .mapLink {
        text-align: right;
        margin-top: 10px;
        @include mq(minTb) {
            display: none; }
        a {
            text-decoration: none;
            position: relative;
            padding-right: 1em;
            &:after {
                content: "\f33b";
                font-family: ionicons;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%); }
            &:hover, &:focus {
                text-decoration: underline; } }
        span {
            text-decoration: none; } } }


.movie {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border: 0 solid #000;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        border: 0 solid #000; } }

.return {
    padding: 40px 20px;
    text-align: center;
    @include mq(minTb) {
        display: none;
        text-align: center; }
    >a {
        background-color: #666;
        border: 1px solid #666;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        padding: 10px 20px;
        width: 100%;
        transition: 0.5s;
        &:hover, &:focus {
            background-color: #fff;
            color: #666; }
        >span {
            @include f-size(16);
            position: relative;
            padding-left: 1rem;
            font-weight: bold;
            &:before {
                content: "\f284";
                font-family: ionicons;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%); } } } }

.selectArea {
    margin-bottom: 60px;
    @include mq(maxTb) {
        margin-bottom: 30px; }
    .select + .select {
        margin-left: 30px;
        @include mq(tb) {
            margin-left: 25px; }
        @include mq(sp) {
            margin-left: 0;
            margin-top: 10px; } }
    &.-event {
        .select + .select {
            @include mq(sp) {
                margin-left: 25px;
                margin-top: 0; } }
        .select {
            @include mq(sp) {
                display: inline-block; } } } }


.eventPager {
    position: relative;
    text-align: center;
    margin-top: 80px;
    @include mq(minTb) {
        margin-top: 40px; }
    .before {
        text-decoration: none;
        position: absolute;
        left: 0;
        top: 50%;
        @include mq(minTb) {
            transform: translateY(-50%); }
        @include mq(maxTb) {
            top: 0; }
        &:before {
            content: "\f282";
            font-family: "ionicons";
            margin-right: 10px;
            color: $keyb; }
        span {
            text-decoration: underline; }
        &:hover, &:focus {
            span {
                text-decoration: none; } } }
    .next {
        text-decoration: none;
        position: absolute;
        right: 0;
        top: 50%;
        @include mq(minTb) {
            transform: translateY(-50%); }
        @include mq(maxTb) {
            top: 0; }
        &:after {
            content: "\f284";
            font-family: "ionicons";
            margin-left: 10px;
            color: $keyb; }
        &:hover, &:focus {
            span {
                text-decoration: none; } }

        span {
            text-decoration: underline; } }
    .lineBtn {
        @include mq(minTb) {
            max-width: 350px; }
        @include mq(maxTb) {
            margin-top: 40px; } } }

.eventDate {
    text-align: right;
    margin-bottom: 30px;
    font-weight: bold; }

.waveTtl + .eventDate {
    margin-top: 10px; }

.admissionArea {
    background-color: $keybb;
    width: 100%;
    padding: 15px 40px;
    display: flex;
    margin-bottom: 50px;
    @include mq(maxTb) {
        padding: 15px 20px;
        display: block;
        margin-bottom: 20px; }
    .left {
        color: #fff;
        width: calc(100% - 270px);
        padding-right: 40px;
        display: flex;
        align-items: center;
        @include mq(maxTb) {
            width: 100%;
            padding-right: 0; }
        p {
            margin: 0;
            @include mq(maxTb) {
                @include f-size(16); } } }
    .right {
        width: 270px;
        @include mq(maxTb) {
            width: 100%;
            margin-top: 10px; }
        .lineBtn {
            padding: 10px 20px; } } }

.searchTxt {
    text-align: center;
    margin: 40px 0 60px;
    font-weight: bold;
    color: $keybb;
    @include mq(sp) {
        text-align: left;
        margin: 30px 0 30px; } }
.mapOuter {
    position: relative;
    >a {
        position: absolute;
        top: 0;
        z-index: 1;
        color: $keyb;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 2px 10px;
        right: 10px;
        top: 10px;
        display: inline-block;
        @include f-size(14);
        transition: 0.5s;
        &:hover, &:focus {
            background-color: #ddd; }
        @include mq(maxTb) {
            right: inherit;
            left: 10px; }
        span {
            &:before {
                content: "\f366";
                font-family: "ionicons";
                margin-right: 5px; } } }


    .map {
        display: inline-block;
        height: 750px;
        width: 100%;
        border: 1px solid #ccc;
        @include mq(maxTb) {
            height: 375px; } } }

.conditionsTable + .mapOuter {
    margin-top: 50px;
    @include mq(maxTb) {
        margin-top: 30px; } }

.mapBlock {
    display: flex;
    border-bottom: 1px solid #6d6a6a;
    padding-bottom: 30px;
    position: relative;
    @include mq(maxTb) {
        display: block;
        width: 100%; }
    &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 30px 0;
        border-color: #6d6a6a transparent transparent transparent;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        position: absolute; }
    >.left {
        .head {
            background-color: $keybb;
            border: 1px solid $keybb;
            color: #fff;
            padding: 0;
            position: relative;
            text-decoration: none;
            display: inline-block;
            text-align: center;
            @include mq(minTb) {
                pointer-events: none;
                width: 250px; }
            @include mq(maxTb) {
                width: 100%; }
            .inner {
                position: relative;
                @include mq(maxTb) {
                    display: inline-block; }
                margin: 0;
                padding: 20px;
                @include mq(maxTb) {
                    padding: 10px 20px; }
                .name {
                    margin: 0;
                    @include f-size(24);
                    @include mq(maxTb) {
                        @include f-size(19); } }
                >svg {
                    fill: #fff;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    width: 28px;
                    transform: translateY(-50%);
                    transition: 0.5s;
                    @include mq(maxTb) {
                        transform: translate(-100%, -50%); } } } } }
    >.right {
        display: flex;
        width: calc(100% - 250px);
        padding-left: 40px;
        align-items: center;
        @include mq(pc) {
            display: block;
            width: 100%; }
        @include mq(maxTb) {
            display: block;
            width: 100%;
            padding-left: 0; }
        >.left {
            >.select {
                @include mq(maxTb) {
                    width: 100%;
                    display: flex;
                    margin-top: 20px; }
                >label {
                    @include mq(maxTb) {
                        display: flex;
                        align-items: center;
                        padding-right: 5px;
                        @include f-size(16);
                        margin-bottom: 0; } }
                >.position {
                    @include mq(maxTb) {
                        width: calc(100% - 38px);
                        @include f-size(16); }
                    select {
                        width: auto;
                        @include mq(maxTb) {
                            width: inherit;
                            @include f-size(16); } } } } }
        >.right {
            padding-left: 40px;
            @include mq(pc) {
                padding-left: 0;
                padding-top: 15px; }
            @include mq(maxTb) {
                padding-left: 0;
                padding-top: 20px; }
            >.select {
                @include mq(maxTb) {
                    width: 100%;
                    display: flex; }
                >label {
                    @include mq(maxTb) {
                        display: flex;
                        align-items: center;
                        padding-right: 5px;
                        @include f-size(16);
                        margin-bottom: 0; } }
                >.position {
                    @include mq(maxTb) {
                        width: calc(100% - 38px);
                        @include f-size(16); }
                    select {
                        width: auto;
                        @include mq(maxTb) {
                            width: inherit;
                            @include f-size(16); } } } } } } }

.mapSubmit {
    max-width: 430px;
    width: 100%;
    margin: 60px auto 0;
    @include mq(maxTb) {
        margin: 40px auto 0; }
    button {
        cursor: pointer; } }

.miniTxt {
    font-size: 0.8em; }

.notFoundTxt {
    max-width: 710px;
    margin: 0 auto; }
