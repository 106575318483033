@charset "utf-8";

* {
    box-sizing: border-box;
    word-break : break-all; }

html {
    font-size: 62.5%;
    -ms-touch-action: manipulation;
    touch-action: manipulation; }

body {
    @include f-size(16);
    line-height: 1.6;
    margin: 0;
    color: #333;
    @include mq(maxTb) {
        @include f-size(14); }
    .android & {
        -webkit-text-size-adjust: 100%; } }

img {
    max-width: 100%;
    height: auto; }

a {
    text-decoration: underline;
    &:hover {
        text-decoration: none; } }

ul {
    padding-left: 0;
    list-style-type: none; }

* {
    font-size: 1em;
    .android & {
        max-height: 999999px; } }

input[type="text"] {
    &:focus,&:hover {
        box-shadow: 0;
        // outline: 0
        border: none; } }
