@charset "utf-8";

footer {
    background-image: url('/assets/img/cmn/bg_gray.jpg');
    >.cont {
        >.inner {
            width: 100%;
            display: flex;
            padding: 70px 0;
            @include mq(maxTb) {
                padding: 40px 0;
                display: block; }
            >.left {
                width: 50%;
                padding-right: 40px;
                box-sizing: border-box;
                @include mq(minPcLimit) {
                    padding-right: 20px; }
                @include mq(pc) {
                    padding-right: 15px; }
                @include mq(maxTb) {
                    width: 100%;
                    padding-right: 0; }
                .waveTtl.-short {
                    .inner {
                        @include mq(maxTb) {
                            width: 100%!important; } } }

                >.name {
                    @include f-size(24);
                    text-align: center;
                    background-color: #fff;
                    padding: 20px;
                    box-sizing: border-box;
                    line-height: 1.2;
                    @include mq(pc) {
                        @include f-size(22); }
                    @include mq(maxTb) {
                        @include f-size(19); }
                    >span {
                        @include f-size(16);
                        @include mq(maxTb) {
                            @include f-size(14); } } }
                .waveTtl + .name {
                    margin-top: 30px; }
                >.address {
                    text-align: center;
                    margin: 30px 0 0;
                    @include f-size(20);
                    @include mq(maxTb) {
                        @include f-size(17); } }
                >.telList {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    @include mq(pcWide) {
                        display: block;
                        text-align: center;
                        margin-top: 10px; }
                    @include mq(maxTb) {
                        display: block;
                        text-align: center;
                        margin-top: 10px; }
                    >li {
                        >p {
                            transform: scale(0.9,1);
                            transform-origin: 0 0;
                            margin: 0;
                            width: 110%;
                            @include f-size(30);
                            font-weight: bold;
                            box-sizing: border-box;
                            text-align: center;
                            @include mq(maxTb) {
                                width: 100%;
                                transform: scale(1,1);
                                @include f-size(24); } } } }
                >.footList {
                    display: flex;
                    justify-content: space-between;
                    margin: 90px 0 0 0;
                    @include mq(pcWide) {
                        margin: 30px 0 0 0;
                        display: block; }
                    @include mq(maxTb) {
                        margin: 30px 0 0 0;
                        display: block; }
                    >li {
                        width: calc(50% - 10px);
                        position: relative;
                        @include mq(pcWide) {
                            width: 100%; }
                        @include mq(maxTb) {
                            width: 100%; }
                        &:nth-of-type(2) {
                            @include mq(pcWide) {
                                margin-top: 40px; }
                            @include mq(maxTb) {
                                margin-top: 40px; } }
                        .lineBtn {
                            padding: 10px 10px; }
                        >.caption {
                            position: absolute;
                            left: 50%;
                            text-outline: 0;
                            transform: translate(-50%, -100%);
                            color: $keybb;
                            display: inline-block;
                            margin: 0;
                            top: -5px;
                            white-space: nowrap;
                            font-weight: bold;
                            &:before {
                                content: "";
                                width: 1px;
                                height: 1em;
                                display: inline-block;
                                background-color: $keybb;
                                transform: rotate(-30deg) translateY(2px);
                                margin-right: 10px; }
                            &:after {
                                content: "";
                                width: 1px;
                                height: 1em;
                                display: inline-block;
                                background-color: $keybb;
                                transform: rotate(30deg) translateY(2px);
                                margin-left: 10px; } } } } }

            >.right {
                width: 50%;
                padding-left: 40px;
                box-sizing: border-box;
                @include mq(minPcLimit) {
                    padding-left: 20px; }
                @include mq(pc) {
                    padding-left: 15px; }
                @include mq(maxTb) {
                    padding-left: 0;
                    margin-top: 40px;
                    width: 100%; }
                >.rightCont {
                    background-color: #fff;
                    padding: 30px;
                    position: relative;
                    @include mq(pcWide) {
                        height: 100%; }
                    @include mq(maxTb) {
                        padding: 20px; }
                    .triangle {
                        background: #1ebee3;
                        background-image: radial-gradient(#62d2eb 20%, transparent 0), radial-gradient(#62d2eb 20%, transparent 0);
                        background-position: 0 0, 10px 10px;
                        background-size: 20px 20px;
                        width: 15%;
                        &.-left {
                            position: absolute;
                            left: 0;
                            top: 0;
                            .position {
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 200%;
                                    height: 200%;
                                    display: inline-block;
                                    background-color: #fff;
                                    transform: translate(0, 20%) rotate(45deg); } } }
                        &.-right {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            .position {
                                &:after {
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    width: 200%;
                                    height: 200%;
                                    display: inline-block;
                                    background-color: #fff;
                                    transform: translate(0, -20%) rotate(45deg); } } }
                        .position {
                            position: relative;
                            overflow: hidden;
                            &:before {
                                content: "";
                                padding-top: 100%;
                                display: block; } } }
                    >.inner {
                        position: relative;
                        >.ttl {
                            color: $keybb;
                            @include f-size(30);
                            text-align: center;
                            margin: 0;
                            @include mq(maxTb) {
                                @include f-size(22); }
                            @include mq(sp) {
                                @include f-size(21); }
                            >span {
                                @include f-size(21);
                                @include mq(maxTb) {
                                    @include f-size(15); } } }
                        >.img {
                            display: flex;
                            margin: 30px 0 0;
                            @include mq(maxTb) {
                                margin: 15px 0 0;
                                justify-content: center; }
                            .left {
                                padding-right: 5px;
                                @include mq(maxTb) {
                                    padding-right: 2px; }
                                >img {
                                    width: 100%;
                                    max-width: 283px; } }
                            .right {
                                padding-left: 5px;
                                @include mq(maxTb) {
                                    padding-left: 2px; }
                                >img {
                                    width: 100%;
                                    max-width: 207px; } }
                            img {
                                vertical-align: bottom; } }
                        >p {
                            text-align: center;
                            margin: 30px 0 0;
                            @include mq(maxTb) {
                                margin: 15px 0 0; } }
                        >.footList {
                            margin: 30px 0 0;
                            display: flex;
                            @include mq(pcWide) {
                                display: block; }
                            @include mq(maxTb) {
                                display: block;
                                margin: 15px 0 0; }
                            li {
                                padding-right: 5px;
                                width: 50%;
                                box-sizing: border-box;
                                @include mq(pcWide) {
                                    width: 100%;
                                    padding-right: 0; }
                                @include mq(maxTb) {
                                    width: 100%;
                                    padding-right: 0; }
                                &:nth-of-type(2) {
                                    padding-left: 5px;
                                    padding-right: 0;
                                    @include mq(pcWide) {
                                        padding-left: 0;
                                        margin-top: 20px; }
                                    @include mq(maxTb) {
                                        padding-left: 0;
                                        margin-top: 15px; } }
                                .lineBtn {
                                    padding: 10px 10px; } } } } } } } }
    .bottom {
        background-color: #fff;
        border-top: 1px solid #cdcdcd;
        >.cont {
            >.inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 0;
                @include mq(pc) {
                    display: block; }
                @include mq(maxTb) {
                    display: block; }
                .left {
                    @include mq(maxTb) {
                        text-align: center; } }
                .right {
                    @include mq(pc) {
                        text-align: center; }
                    @include mq(maxTb) {
                        text-align: center; } } } }
        .footBottomList {
            display: flex;
            margin: 0;
            @include mq(maxTb) {
                flex-wrap: wrap;
                justify-content: center; }
            >li {
                &:after {
                    content: "/";
                    margin: 0 5px; }
                &:last-of-type {
                    &:after {
                        margin: 0;
                        content: ""; } } } } } }

.conditionsSubmit {
    max-width: 430px;
    width: 100%;
    margin: 60px auto 0;
    @include mq(maxTb) {
        margin: 40px auto 0; }
    button {
        cursor: pointer; } }
