@charset "utf-8";

.conditionsTable {
    border: 1px solid #999;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    .safari & {
        table-layout: auto; }
    @include mq(maxTb) {
        opacity: 0;
        visibility: hidden;
        height: 0;
        margin-top: 0;
        overflow: hidden;
        display: block; }
    >tbody {
        >tr {
            @include mq(maxPc) {
                display: block; }
            >th {
                background-color: #e6e6e6;
                vertical-align: top;
                padding: 15px 10px 10px;
                width: 10%;
                @include mq(maxPc) {
                    display: block;
                    width: 100%;
                    padding: 10px 10px 10px; } }
            >td {
                padding: 10px;
                border-left: 1px solid #999;
                width: 30%;
                vertical-align: top;
                @include mq(maxPc) {
                    display: block;
                    width: 100%;
                    border-top: 1px solid #999;
                    border-left: 0px solid #999; }
                >.tableCont {
                    width: 100%;
                    display: flex;
                    @include mq(minPcLimit) {
                        display: block; }
                    >.left {
                        width: 3em;
                        margin: 0;
                        @include mq(minPcLimit) {
                            width: 100%; }
                        @include mq(maxPc) {
                            width: 7em; }
                        >span {
                            padding: 5px;
                            background-color: $keybb;
                            color: #fff;
                            width: 100%;
                            text-align: center;
                            display: inline-block; } }
                    >.right {
                        width: calc(100% - 3em);
                        padding-left: 10px;
                        padding-top: 5px;
                        @include mq(minPcLimit) {
                            width: 100%;
                            padding-left: 0; }
                        @include mq(maxPc) {
                            width: calc(100% - 7em);
                            box-sizing: border-box; }
                        >p {
                            font-weight: bold;
                            margin: 0; }
                        >ul {
                            margin: 0; } } }
                &:nth-of-type(2) {
                    >.tableCont {
                        >.right {
                            >ul {
                                >li {
                                    display: inline-block;
                                    &:after {
                                        content: ','; }
                                    &:last-of-type {
                                        &:after {
                                            display: none; } } } } } } }
                &:nth-of-type(3) {
                    >.tableCont {
                        >.left {
                            width: 7em;
                            @include mq(minPcLimit) {
                                width: 100%; } }
                        >.right {
                            width: calc(100% - 7em);
                            @include mq(minPcLimit) {
                                width: 100%; } } } } } } }

    &.-companies {
        >tbody {
            >tr {
                >th {
                    width: 250px;
                    vertical-align: middle;
                    @include mq(maxPc) {
                        width: 100%; } }
                >td {
                    width: calc(100% - 250px);
                    @include mq(maxPc) {
                        width: 100%; }
                    >.tableCont {
                        width: 100%;
                        display: flex;
                        @include mq(minPcLimit) {
                            display: flex; }
                        >.left {
                            width: 6em;
                            @include mq(minPcLimit) {
                                width: 6em; }
                            @include mq(maxPc) {
                                width: 6em; } }
                        >.right {
                            width: calc(100% - 5em);
                            @include mq(minPcLimit) {
                                width: calc(100% - 5em);
                                padding-left: 10px; }
                            @include mq(maxPc) {
                                width: calc(100% - 5em); } } } } } } }
    &.-map {
        margin-top: 60px;
        @include mq(maxTb) {
            margin-top: 0; }
        >tbody {
            >tr {
                >th {
                    width: 250px;
                    vertical-align: top;
                    @include mq(maxPc) {
                        width: 100%; } }
                >td {
                    width: calc(100% - 250px);
                    @include mq(maxPc) {
                        width: 100%; }
                    .layoutInner {
                        display: flex;
                        @include mq(minPcLimit) {
                            display: block; }
                        .column {
                            width: calc(50% - 25px);
                            @include mq(minPcLimit) {
                                width: 100%; }
                            @include mq(pc) {
                                width: calc(50% - 15px); }
                            @include mq(maxTb) {
                                width: 100%; }
                            &:nth-of-type(2) {
                                margin-left: 50px;
                                @include mq(minPcLimit) {
                                    margin-left: 0;
                                    margin-top: 20px; }
                                @include mq(pc) {
                                    margin-left: 30px; }
                                @include mq(maxTb) {
                                    margin-left: 0;
                                    margin-top: 20px; } }
                            >.tableCont {
                                display: flex;
                                >.left {
                                    width: 3em;
                                    margin: 0;
                                    >span {
                                        padding: 5px;
                                        background-color: $keybb;
                                        color: #fff;
                                        width: 100%;
                                        text-align: center;
                                        display: inline-block; }
                                    >span {
                                        padding: 5px;
                                        background-color: $keybb;
                                        color: #fff;
                                        width: 100%;
                                        text-align: center;
                                        display: inline-block; } }
                                >.right {
                                    width: calc(100% - 3em);
                                    padding-top: 5px;
                                    padding-left: 10px;
                                    @include mq(minPcLimit) {
                                        width: calc(100% - 3em);
                                        padding-left: 10px; }
                                    p {
                                        margin: 0; } } } } } } } } } }


form + .conditionsTable {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 0; } }

.nameList + .conditionsTable {
    margin-top: 60px;
    @include mq(maxTb) {
        margin-top: 0; } }

.workTable {
    border-left: 1px solid #999;
    border-collapse: collapse;
    width: 100%;
    @include mq(maxTb) {
        display: block; }
    tbody {
        @include mq(maxTb) {
            width: 100%;
            display: block; }
        tr {
            @include mq(maxTb) {
                width: 100%;
                display: block; }
            th {
                background-color: #f2f2f2;
                padding: 10px 15px;
                font-weight: bold;
                width: 290px;
                border: 1px solid #999;
                @include mq(pc) {
                    width: 200px; }
                @include mq(maxTb) {
                    width: 100%;
                    display: block;
                    border-left: 0 solid #999;
                    border-top: 0 solid #999;
                    border-bottom: 0 solid #999;
                    margin-top: -1px; } }
            td {
                border: 1px solid #999;
                padding: 10px 15px;
                @include mq(maxTb) {
                    width: 100%;
                    display: block;
                    border-left: 0 solid #999; } } }
        &:nth-of-type(1) {
            tr {
                th {
                    @include mq(maxTb) {
                        border-top: 1px solid #999; } } } } } }

table.eventTable {
    border-collapse: collapse;
    border: 1px solid #999;
    width: 100%;
    tbody {
        tr {
            @include mq(maxTb) {
                width: 100%;
                display: block;
                float: left; }
            &:last-of-type {
                td {
                    @include mq(maxTb) {
                        border-bottom: 0px solid #999; } } }
            th {
                background-color: #f2f2f2;
                border: 1px solid #999;
                padding: 20px;
                vertical-align: top;
                text-align: center;
                width: 290px;
                @include mq(maxTb) {
                    width: 100%;
                    display: block;
                    float: left;
                    white-space: inherit;
                    padding: 10px;
                    border: 0px solid #999;
                    border-bottom: 1px solid #999;
                    text-align: left; } }
            td {
                border: 1px solid #999;
                padding: 20px;
                @include mq(maxTb) {
                    width: 100%;
                    display: block;
                    float: left;
                    padding: 10px;
                    border: 0px solid #999;
                    border-bottom: 1px solid #999; }
                a[target ='_blank'] {
                    position: relative;
                    padding-right: 1em;
                    text-decoration: none;
                    &:hover,&:focus {
                        span {
                            text-decoration: none; } }
                    &:after {
                        position: absolute;
                        content: "\f33b";
                        font-family: "ionicons";
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%); }
                    span {
                        text-decoration: underline; } }
                a[href $='.pdf'] {
                    position: relative;
                    padding-right: 1em;
                    text-decoration: none;
                    &:hover,&:focus {
                        span {
                            text-decoration: none; } }
                    &:after {
                        position: absolute;
                        content: "\f1c1";
                        font-family: "Font Awesome 5 Free";
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%); }
                    span {
                        text-decoration: underline; } }
                a[href $='.doc'] {
                    position: relative;
                    padding-right: 1em;
                    text-decoration: none;
                    &:hover,&:focus {
                        span {
                            text-decoration: none; } }
                    &:after {
                        position: absolute;
                        content: "\f1c2";
                        font-family: "Font Awesome 5 Free";
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%); }
                    span {
                        text-decoration: underline; } }
                a[href $='.xls'] {
                    position: relative;
                    padding-right: 1em;
                    text-decoration: none;
                    &:after {
                        position: absolute;
                        content: "\f1c3";
                        font-family: "Font Awesome 5 Free";
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%); }
                    span {
                        text-decoration: underline; } }
                a[href $='.zip'] {
                    position: relative;
                    padding-right: 1em;
                    text-decoration: none;
                    &:hover,&:focus {
                        span {
                            text-decoration: none; } }
                    &:after {
                        position: absolute;
                        content: "\f1c6";
                        font-family: "Font Awesome 5 Free";
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%); }
                    span {
                        text-decoration: underline; } } } } } }

.contactTable {
    border-collapse: collapse;
    border: 1px solid #999;
    width: 100%;
    table-layout: fixed;
    tr {
        @include mq(maxTb) {
            width: 100%;
            display: block;
            float: left; }
        &.error {
            th {
                padding: 50px 20px 20px;
                @include mq(maxTb) {
                    padding: 15px; } }
            td {
                background-color: #ffe9eb; } }
        th {
            background-color: #f2f2f2;
            border: 1px solid #999;
            padding: 30px 20px 20px;
            vertical-align: top;
            text-align: left;
            white-space: nowrap;
            @include mq(minTb) {
                width: 230px; }
            @include mq(maxTb) {
                width: 100%;
                display: block;
                float: left;
                white-space: inherit;
                padding: 15px;
                border: 0px solid #999;
                border-bottom: 1px solid #999; }
            .required {
                position: relative;
                width: 100%;
                display: block;
                padding-right: 4em;
                &:after {
                    content: "※ 必須";
                    right: 0;
                    top: 0;
                    color: #cc0101;
                    position: absolute;
                    font-weight: normal; } }
            span.line {
                display: block;
                @include mq(maxTb) {
                    display: inline;
                    margin-left: 5px; } } }
        td {
            border: 1px solid #999;
            padding: 20px;
            @include mq(maxTb) {
                width: 100%;
                display: block;
                float: left;
                padding: 15px;
                border: 0px solid #999;
                border-bottom: 1px solid #999; }
            .errorTxt {
                color: #cc0101; }
            >p {
                margin: 0; }
            >* + p {
                margin-top: 10px; }
            p + .inputTxt {
                margin-top: 20px; }
            fieldset {
                >p {
                    margin: 0; }
                >* + p {
                    margin-top: 10px; }
                p + .inputTxt {
                    margin-top: 20px; } } }
        &:last-of-type {
            td {
                @include mq(maxTb) {
                    border-bottom: 0px solid #999; } } } } }

table.businessTable {
    border-collapse: collapse;
    margin-top: 30px;
    @include mq(maxTb) {
        margin-top: 25px; }
    thead {
        tr {
            th {
                background-color: #d1e9f5;
                border: 1px solid #999;
                border-left: 0px solid #999;
                text-align: center;
                padding: 10px 15px;
                width: calc(100% / 3);
                @include mq(maxTb) {
                    display: none; }
                &:nth-of-type(1) {
                    border-left: 1px solid #999; } } } }
    tbody {
        >tr + tr {
            @include mq(maxTb) {
                margin-top: 30px; } }
        tr {
            @include mq(maxTb) {
                float: left;
                display: block;
                width: 100%; }
            th {
                border: 1px solid #999;
                border-bottom: 0px solid #999;
                padding: 10px 15px;
                text-align: left;
                vertical-align: top;
                position: relative;
                @include mq(maxTb) {
                    padding: 10px 10px;
                    float: left;
                    display: block;
                    width: 100%; }
                p {
                    margin: 0;
                    position: relative;
                    font-weight: normal;
                    @include mq(maxTb) {
                        padding-left: calc(5em + 20px); }
                    &:before {
                        @include mq(maxTb) {
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            content: "業種名";
                            background-color: #d1e9f5;
                            height: 100%;
                            display: inline-block;
                            width: 5em;
                            text-align: center;
                            padding: 10px;
                            font-weight: bold; } } } }
            td {
                border: 1px solid #999;
                border-bottom: 0px solid #999;
                border-left: 0px solid #999;
                padding: 10px 15px;
                vertical-align: top;
                @include mq(maxTb) {
                    padding: 10px 10px;
                    float: left;
                    display: block;
                    width: 100%;
                    border-left: 1px solid #999; }
                &:nth-of-type(2) {
                    border-bottom: 1px solid #999;
                    p {
                        &:before {
                            @include mq(maxTb) {
                                content: "詳細・例"; } } } }
                p {
                    margin: 0;
                    position: relative;
                    @include mq(maxTb) {
                        padding-left: calc(5em + 20px); }
                    &:before {
                        @include mq(maxTb) {
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            content: "内容";
                            background-color: #d1e9f5;
                            height: 100%;
                            display: inline-block;
                            width: 5em;
                            text-align: center;
                            padding: 10px;
                            font-weight: bold; } } } }

            &:last-of-type {
                th {
                    border-bottom: 1px solid #999;
                    @include mq(maxTb) {
                        border-bottom: 0px solid #999; } }
                td {
                    border-bottom: 1px solid #999;
                    @include mq(maxTb) {
                        border-bottom: 0px solid #999;
                        &:last-of-type {
                            border-bottom: 1px solid #999; } } } } } } }
