@charset "UTF-8";

$maxWidth: 1200px;

//color

//黒色
$keyb: #333;

//水色
$keylb: #1ebee3;

//紺色
$keybb: #2660a1;

//黄色
$keyly: #f2de00;
